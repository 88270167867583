<template>
  <div>
    <template>
      <div class="sfprice__module">
        <div
          class="special-price"
          v-if="
            roundedSpecialPrice && roundedSpecialPrice < roundedRegularPrice
          "
        >
          <span class="sfprice-special">
            {{ $fc(roundedSpecialPrice) }}
          </span>
          <span class="sfprice__regular">
            {{ $fc(roundedRegularPrice) }}
          </span>
        </div>
        <span class="sfprice__regular" v-else>
          {{ $fc(roundedRegularPrice) }}
        </span>
        <span
          class="sfprice-discount"
          v-if="roundedSpecialPrice < roundedRegularPrice && productDiscount"
        >
          {{ productDiscount }}
        </span>
      </div>
    </template>
  </div>
</template>

<script>
import { ref, onMounted, defineComponent } from '@nuxtjs/composition-api';
import productDetailVariantPrice from '~/helpers/product/productDetailVariantPrice';
import useApi from '~/composables/useApi';
import { cartHelpers } from '~/helpers/cart/cartHelpers';
import { useCart } from '@gemini-vsf/composables';

export default defineComponent({
  name: 'SfPrice',
  props: {
    regular: {
      type: [String, Number],
      default: null,
    },
    special: {
      type: [String, Number],
      default: null,
    },
    product: {
      type: Object,
      default: () => ({}),
    },
    handleCartDiscounts: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { cart } = useCart();
    const { calculateDiscountPercentages, getDiscountLabel } = cartHelpers();
    const roundedRegularPrice = ref(
      props.regular > 0
        ? Number.parseFloat((Math.round(props.regular * 100) / 100).toFixed(2))
        : 0
    );

    const roundedSpecialPrice = ref(
      props.special > 0
        ? Number.parseFloat((Math.round(props.special * 100) / 100).toFixed(2))
        : 0
    );

    const { query } = useApi();
    const fetchVariant = async (product) => {
      const uid = product.product.uid;
      const configurableOptionValueUids =
        product.configurable_options?.[0]
          ?.configurable_product_option_value_uid;
      if (!uid || !configurableOptionValueUids) {
        return null;
      }
      const searchQuery = {
        filter: {
          uid,
        },
        configurableOptionValueUids,
      };
      const { data } = await query(productDetailVariantPrice, searchQuery);
      return data?.productDetail?.configurable_product_options_selection
        ?.variant;
    };

    const getCartDiscounts = async () => {
      let variant =
        props.product.product?.configurable_product_options_selection?.variant;
      if (!variant) {
        variant = await fetchVariant(props.product);
      }
      if (variant) {
        const catalogFinalPrice =
          variant.price_range?.minimum_price?.final_price?.value *
          props.product?.quantity;
        if (catalogFinalPrice && props.regular >= catalogFinalPrice) {
          const onlyCartRulesCartTotal = cart.value.items?.reduce(
            (acc, item) => {
              // if a product has no cart discount, remove from cart total to correctly calculate item price weight
              return item.product.price_range?.minimum_price?.regular_price
                ?.value -
                item.product.price_range?.minimum_price?.final_price?.value ===
                0 && item.prices.regular_price - item.prices.price.value === 0
                ? acc - item.prices.row_regular_total.value
                : acc;
            },
            cart.value?.prices?.subtotal_including_tax?.value
          );
          const cartItemPriceWeight =
            catalogFinalPrice / onlyCartRulesCartTotal;

          const {
            cartRuleItemDiscountsPercentage,
            couponItemDiscountPercentage,
          } = calculateDiscountPercentages({
            cartItemPriceWeight,
            catalogFinalPrice,
          });

          const catalogDiscount =
            ((props.regular - catalogFinalPrice) / props.regular) * 100;
          return getDiscountLabel({
            catalogDiscount,
            cartRuleItemDiscountsPercentage,
            couponItemDiscountPercentage,
          });
        }
      }
      return '';
    };
    const getProductDiscount = async () => {
      if (props.special > 0 && props.regular > props.special) {
        if (props.handleCartDiscounts && props.product?.product) {
          const cartDiscounts = await getCartDiscounts();
          if (cartDiscounts) {
            return cartDiscounts;
          }
        }
        const calcDiscount =
          ((props.regular - props.special) / props.regular) * 100;
        return calcDiscount ? `${Math.round(calcDiscount)}%` : '';
      }
      return '';
    };
    const productDiscount = ref('');
    onMounted(async () => {
      productDiscount.value = await getProductDiscount();
    });

    return {
      productDiscount,
      roundedRegularPrice,
      roundedSpecialPrice,
    };
  },
});
</script>

<style lang="scss">
.sfprice__module {
  font-family: 'BarlowCondensed-Regular';
  display: flex;
  align-items: baseline;
  font-size: 16px;
  line-height: 20px;
  .special-price {
    span {
      &.sfprice__regular {
        color: var(--c-dark-grey);
        text-decoration-line: line-through;
        font-weight: normal;
      }
    }
  }
  span {
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
    &.sfprice-discount {
      color: #e50000;
      margin-left: 8px;
      margin-right: 10px;
      font-weight: normal;
    }
  }
}
@media (max-width: 385px) {
  .sfprice__module {
    .special-price {
      .sfprice-special {
        margin-right: 4px;
      }
    }
    span {
      &.sfprice-discount {
        margin-left: 4px;
        margin-right: 0;
      }
    }
  }
}
@media (max-width: 350px) {
  .sfprice__module {
    font-size: 14px;
    line-height: 16px;
    .special-price {
      .sfprice-special {
        margin-right: 2px;
      }
    }
    span {
      &.sfprice-discount {
        margin-left: 2px;
        margin-right: 0;
      }
    }
  }
}
</style>
