import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5a6ec0f0 = () => interopDefault(import('../pages/HomePage.vue' /* webpackChunkName: "" */))
const _34cef09d = () => interopDefault(import('../pages/Boutiques.vue' /* webpackChunkName: "" */))
const _544757fc = () => interopDefault(import('../pages/Cart.vue' /* webpackChunkName: "pages/Cart" */))
const _0b2841e0 = () => interopDefault(import('../pages/Category.vue' /* webpackChunkName: "pages/Category" */))
const _48ef6e28 = () => interopDefault(import('../pages/Checkout.vue' /* webpackChunkName: "" */))
const _74dcbd02 = () => interopDefault(import('../pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _15bc54f6 = () => interopDefault(import('../pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _42a0bda8 = () => interopDefault(import('../pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _7855b4db = () => interopDefault(import('../pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _26e24f74 = () => interopDefault(import('../pages/Checkout/Billing.vue' /* webpackChunkName: "pages/Checkout/Billing" */))
const _b1da17a4 = () => interopDefault(import('../pages/Contact.vue' /* webpackChunkName: "pages/Contact" */))
const _5bdc71ad = () => interopDefault(import('../pages/GuestReturns.vue' /* webpackChunkName: "" */))
const _b971c876 = () => interopDefault(import('../pages/Journal.vue' /* webpackChunkName: "pages/Journal" */))
const _bf42fcec = () => interopDefault(import('../pages/JournalView.vue' /* webpackChunkName: "pages/JournalView" */))
const _10189cef = () => interopDefault(import('../pages/MyAccount.vue' /* webpackChunkName: "pages/MyAccount" */))
const _6f2f1c30 = () => interopDefault(import('../pages/MyAccount/AddressesDetails.vue' /* webpackChunkName: "pages/MyAccount/AddressesDetails" */))
const _41bcf037 = () => interopDefault(import('../pages/MyAccount/MyChanges.vue' /* webpackChunkName: "pages/MyAccount/MyChanges" */))
const _03b110f5 = () => interopDefault(import('../pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "pages/MyAccount/MyNewsletter" */))
const _a43397c6 = () => interopDefault(import('../pages/MyAccount/MyProfile.vue' /* webpackChunkName: "pages/MyAccount/MyProfile" */))
const _4bc3b6b7 = () => interopDefault(import('../pages/MyAccount/MyReturns.vue' /* webpackChunkName: "pages/MyAccount/MyReturns" */))
const _9222d5fe = () => interopDefault(import('../pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "pages/MyAccount/MyWishlist" */))
const _42139b54 = () => interopDefault(import('../pages/MyAccount/OrderHistory.vue' /* webpackChunkName: "pages/MyAccount/OrderHistory" */))
const _0b5f7600 = () => interopDefault(import('../pages/MyAccount/_MyReviews.vue' /* webpackChunkName: "pages/MyAccount/_MyReviews" */))
const _a85becca = () => interopDefault(import('../pages/Newsletter.vue' /* webpackChunkName: "" */))
const _4eee96c6 = () => interopDefault(import('../pages/OurBrands.vue' /* webpackChunkName: "pages/OurBrands" */))
const _08b2ebf1 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _680ee06a = () => interopDefault(import('../pages/PayloadPage.vue' /* webpackChunkName: "pages/PayloadPage" */))
const _4bba9d7d = () => interopDefault(import('../pages/Product.vue' /* webpackChunkName: "pages/Product" */))
const _693350d0 = () => interopDefault(import('../pages/ResetPassword.vue' /* webpackChunkName: "" */))
const _34cba952 = () => interopDefault(import('../pages/Route.vue' /* webpackChunkName: "pages/Route" */))
const _6f8b500a = () => interopDefault(import('../pages/Search.vue' /* webpackChunkName: "pages/Search" */))
const _3fbd01b2 = () => interopDefault(import('../pages/Wishlist.vue' /* webpackChunkName: "pages/Wishlist" */))
const _5dc06952 = () => interopDefault(import('../pages/WorkWithUs.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ae-en",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___ae-en"
  }, {
    path: "/ae-it",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___ae-it"
  }, {
    path: "/au-en",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___au-en"
  }, {
    path: "/au-it",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___au-it"
  }, {
    path: "/bh-en",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___bh-en"
  }, {
    path: "/bh-it",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___bh-it"
  }, {
    path: "/ca-en",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___ca-en"
  }, {
    path: "/ca-it",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___ca-it"
  }, {
    path: "/ch-en",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___ch-en"
  }, {
    path: "/ch-it",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___ch-it"
  }, {
    path: "/cn-en",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___cn-en"
  }, {
    path: "/cn-it",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___cn-it"
  }, {
    path: "/de-en",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___de-en"
  }, {
    path: "/de-it",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___de-it"
  }, {
    path: "/eue-en",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___eue-en"
  }, {
    path: "/eue-it",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___eue-it"
  }, {
    path: "/gb-en",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___gb-en"
  }, {
    path: "/gb-it",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___gb-it"
  }, {
    path: "/hk-en",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___hk-en"
  }, {
    path: "/hk-it",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___hk-it"
  }, {
    path: "/it-en",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___it-en"
  }, {
    path: "/it-it",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___it-it"
  }, {
    path: "/jp-en",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___jp-en"
  }, {
    path: "/jp-it",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___jp-it"
  }, {
    path: "/kr-en",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___kr-en"
  }, {
    path: "/kr-it",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___kr-it"
  }, {
    path: "/kw-en",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___kw-en"
  }, {
    path: "/kw-it",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___kw-it"
  }, {
    path: "/mo-en",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___mo-en"
  }, {
    path: "/mo-it",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___mo-it"
  }, {
    path: "/qa-en",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___qa-en"
  }, {
    path: "/qa-it",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___qa-it"
  }, {
    path: "/row-en",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___row-en"
  }, {
    path: "/row-it",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___row-it"
  }, {
    path: "/sg-en",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___sg-en"
  }, {
    path: "/sg-it",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___sg-it"
  }, {
    path: "/ue-en",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___ue-en"
  }, {
    path: "/ue-it",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___ue-it"
  }, {
    path: "/uee-en",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___uee-en"
  }, {
    path: "/uee-it",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___uee-it"
  }, {
    path: "/us-en",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___us-en"
  }, {
    path: "/us-it",
    component: _5a6ec0f0,
    meta: {"headerColor":"transparent"},
    name: "homepage___us-it"
  }, {
    path: "/ae-en/boutiques",
    component: _34cef09d,
    name: "boutiques___ae-en",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___ae-en"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___ae-en"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___ae-en"
    }]
  }, {
    path: "/ae-en/Boutiques",
    component: _34cef09d,
    name: "Boutiques___ae-en"
  }, {
    path: "/ae-en/Cart",
    component: _544757fc,
    name: "Cart___ae-en"
  }, {
    path: "/ae-en/Category",
    component: _0b2841e0,
    name: "Category___ae-en"
  }, {
    path: "/ae-en/checkout",
    component: _48ef6e28,
    name: "checkout___ae-en",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___ae-en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___ae-en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___ae-en"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___ae-en"
    }]
  }, {
    path: "/ae-en/Checkout",
    component: _48ef6e28,
    name: "Checkout___ae-en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___ae-en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___ae-en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___ae-en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___ae-en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___ae-en"
    }]
  }, {
    path: "/ae-en/Contact",
    component: _b1da17a4,
    name: "Contact___ae-en"
  }, {
    path: "/ae-en/contacts",
    component: _b1da17a4,
    name: "contacts___ae-en"
  }, {
    path: "/ae-en/contatti",
    component: _b1da17a4,
    name: "contatti___ae-en"
  }, {
    path: "/ae-en/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___ae-en"
  }, {
    path: "/ae-en/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___ae-en"
  }, {
    path: "/ae-en/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___ae-en"
  }, {
    path: "/ae-en/Journal",
    component: _b971c876,
    name: "Journal___ae-en"
  }, {
    path: "/ae-en/JournalView",
    component: _bf42fcec,
    name: "JournalView___ae-en"
  }, {
    path: "/ae-en/MyAccount",
    component: _10189cef,
    name: "MyAccount___ae-en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___ae-en"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___ae-en"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___ae-en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___ae-en"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___ae-en"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___ae-en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___ae-en"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___ae-en"
    }]
  }, {
    path: "/ae-en/newsletter",
    component: _a85becca,
    name: "newsletter___ae-en"
  }, {
    path: "/ae-en/Newsletter",
    component: _a85becca,
    name: "Newsletter___ae-en"
  }, {
    path: "/ae-en/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___ae-en"
  }, {
    path: "/ae-en/Page",
    component: _08b2ebf1,
    name: "Page___ae-en"
  }, {
    path: "/ae-en/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___ae-en"
  }, {
    path: "/ae-en/Product",
    component: _4bba9d7d,
    name: "Product___ae-en"
  }, {
    path: "/ae-en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___ae-en"
  }, {
    path: "/ae-en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___ae-en"
  }, {
    path: "/ae-en/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___ae-en"
  }, {
    path: "/ae-en/Route",
    component: _34cba952,
    name: "Route___ae-en"
  }, {
    path: "/ae-en/Search",
    component: _6f8b500a,
    name: "Search___ae-en"
  }, {
    path: "/ae-en/search-result",
    component: _6f8b500a,
    name: "search-result___ae-en"
  }, {
    path: "/ae-en/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___ae-en"
  }, {
    path: "/ae-en/work-with-us",
    component: _5dc06952,
    name: "work-with-us___ae-en"
  }, {
    path: "/ae-en/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___ae-en"
  }, {
    path: "/ae-it/boutiques",
    component: _34cef09d,
    name: "boutiques___ae-it",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___ae-it"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___ae-it"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___ae-it"
    }]
  }, {
    path: "/ae-it/Boutiques",
    component: _34cef09d,
    name: "Boutiques___ae-it"
  }, {
    path: "/ae-it/Cart",
    component: _544757fc,
    name: "Cart___ae-it"
  }, {
    path: "/ae-it/Category",
    component: _0b2841e0,
    name: "Category___ae-it"
  }, {
    path: "/ae-it/checkout",
    component: _48ef6e28,
    name: "checkout___ae-it",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___ae-it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___ae-it"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___ae-it"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___ae-it"
    }]
  }, {
    path: "/ae-it/Checkout",
    component: _48ef6e28,
    name: "Checkout___ae-it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___ae-it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___ae-it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___ae-it"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___ae-it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___ae-it"
    }]
  }, {
    path: "/ae-it/Contact",
    component: _b1da17a4,
    name: "Contact___ae-it"
  }, {
    path: "/ae-it/contacts",
    component: _b1da17a4,
    name: "contacts___ae-it"
  }, {
    path: "/ae-it/contatti",
    component: _b1da17a4,
    name: "contatti___ae-it"
  }, {
    path: "/ae-it/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___ae-it"
  }, {
    path: "/ae-it/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___ae-it"
  }, {
    path: "/ae-it/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___ae-it"
  }, {
    path: "/ae-it/Journal",
    component: _b971c876,
    name: "Journal___ae-it"
  }, {
    path: "/ae-it/JournalView",
    component: _bf42fcec,
    name: "JournalView___ae-it"
  }, {
    path: "/ae-it/MyAccount",
    component: _10189cef,
    name: "MyAccount___ae-it",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___ae-it"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___ae-it"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___ae-it"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___ae-it"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___ae-it"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___ae-it"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___ae-it"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___ae-it"
    }]
  }, {
    path: "/ae-it/newsletter",
    component: _a85becca,
    name: "newsletter___ae-it"
  }, {
    path: "/ae-it/Newsletter",
    component: _a85becca,
    name: "Newsletter___ae-it"
  }, {
    path: "/ae-it/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___ae-it"
  }, {
    path: "/ae-it/Page",
    component: _08b2ebf1,
    name: "Page___ae-it"
  }, {
    path: "/ae-it/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___ae-it"
  }, {
    path: "/ae-it/Product",
    component: _4bba9d7d,
    name: "Product___ae-it"
  }, {
    path: "/ae-it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___ae-it"
  }, {
    path: "/ae-it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___ae-it"
  }, {
    path: "/ae-it/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___ae-it"
  }, {
    path: "/ae-it/Route",
    component: _34cba952,
    name: "Route___ae-it"
  }, {
    path: "/ae-it/Search",
    component: _6f8b500a,
    name: "Search___ae-it"
  }, {
    path: "/ae-it/search-result",
    component: _6f8b500a,
    name: "search-result___ae-it"
  }, {
    path: "/ae-it/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___ae-it"
  }, {
    path: "/ae-it/work-with-us",
    component: _5dc06952,
    name: "work-with-us___ae-it"
  }, {
    path: "/ae-it/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___ae-it"
  }, {
    path: "/au-en/boutiques",
    component: _34cef09d,
    name: "boutiques___au-en",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___au-en"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___au-en"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___au-en"
    }]
  }, {
    path: "/au-en/Boutiques",
    component: _34cef09d,
    name: "Boutiques___au-en"
  }, {
    path: "/au-en/Cart",
    component: _544757fc,
    name: "Cart___au-en"
  }, {
    path: "/au-en/Category",
    component: _0b2841e0,
    name: "Category___au-en"
  }, {
    path: "/au-en/checkout",
    component: _48ef6e28,
    name: "checkout___au-en",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___au-en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___au-en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___au-en"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___au-en"
    }]
  }, {
    path: "/au-en/Checkout",
    component: _48ef6e28,
    name: "Checkout___au-en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___au-en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___au-en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___au-en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___au-en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___au-en"
    }]
  }, {
    path: "/au-en/Contact",
    component: _b1da17a4,
    name: "Contact___au-en"
  }, {
    path: "/au-en/contacts",
    component: _b1da17a4,
    name: "contacts___au-en"
  }, {
    path: "/au-en/contatti",
    component: _b1da17a4,
    name: "contatti___au-en"
  }, {
    path: "/au-en/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___au-en"
  }, {
    path: "/au-en/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___au-en"
  }, {
    path: "/au-en/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___au-en"
  }, {
    path: "/au-en/Journal",
    component: _b971c876,
    name: "Journal___au-en"
  }, {
    path: "/au-en/JournalView",
    component: _bf42fcec,
    name: "JournalView___au-en"
  }, {
    path: "/au-en/MyAccount",
    component: _10189cef,
    name: "MyAccount___au-en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___au-en"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___au-en"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___au-en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___au-en"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___au-en"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___au-en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___au-en"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___au-en"
    }]
  }, {
    path: "/au-en/newsletter",
    component: _a85becca,
    name: "newsletter___au-en"
  }, {
    path: "/au-en/Newsletter",
    component: _a85becca,
    name: "Newsletter___au-en"
  }, {
    path: "/au-en/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___au-en"
  }, {
    path: "/au-en/Page",
    component: _08b2ebf1,
    name: "Page___au-en"
  }, {
    path: "/au-en/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___au-en"
  }, {
    path: "/au-en/Product",
    component: _4bba9d7d,
    name: "Product___au-en"
  }, {
    path: "/au-en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___au-en"
  }, {
    path: "/au-en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___au-en"
  }, {
    path: "/au-en/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___au-en"
  }, {
    path: "/au-en/Route",
    component: _34cba952,
    name: "Route___au-en"
  }, {
    path: "/au-en/Search",
    component: _6f8b500a,
    name: "Search___au-en"
  }, {
    path: "/au-en/search-result",
    component: _6f8b500a,
    name: "search-result___au-en"
  }, {
    path: "/au-en/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___au-en"
  }, {
    path: "/au-en/work-with-us",
    component: _5dc06952,
    name: "work-with-us___au-en"
  }, {
    path: "/au-en/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___au-en"
  }, {
    path: "/au-it/boutiques",
    component: _34cef09d,
    name: "boutiques___au-it",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___au-it"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___au-it"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___au-it"
    }]
  }, {
    path: "/au-it/Boutiques",
    component: _34cef09d,
    name: "Boutiques___au-it"
  }, {
    path: "/au-it/Cart",
    component: _544757fc,
    name: "Cart___au-it"
  }, {
    path: "/au-it/Category",
    component: _0b2841e0,
    name: "Category___au-it"
  }, {
    path: "/au-it/checkout",
    component: _48ef6e28,
    name: "checkout___au-it",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___au-it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___au-it"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___au-it"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___au-it"
    }]
  }, {
    path: "/au-it/Checkout",
    component: _48ef6e28,
    name: "Checkout___au-it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___au-it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___au-it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___au-it"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___au-it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___au-it"
    }]
  }, {
    path: "/au-it/Contact",
    component: _b1da17a4,
    name: "Contact___au-it"
  }, {
    path: "/au-it/contacts",
    component: _b1da17a4,
    name: "contacts___au-it"
  }, {
    path: "/au-it/contatti",
    component: _b1da17a4,
    name: "contatti___au-it"
  }, {
    path: "/au-it/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___au-it"
  }, {
    path: "/au-it/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___au-it"
  }, {
    path: "/au-it/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___au-it"
  }, {
    path: "/au-it/Journal",
    component: _b971c876,
    name: "Journal___au-it"
  }, {
    path: "/au-it/JournalView",
    component: _bf42fcec,
    name: "JournalView___au-it"
  }, {
    path: "/au-it/MyAccount",
    component: _10189cef,
    name: "MyAccount___au-it",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___au-it"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___au-it"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___au-it"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___au-it"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___au-it"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___au-it"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___au-it"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___au-it"
    }]
  }, {
    path: "/au-it/newsletter",
    component: _a85becca,
    name: "newsletter___au-it"
  }, {
    path: "/au-it/Newsletter",
    component: _a85becca,
    name: "Newsletter___au-it"
  }, {
    path: "/au-it/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___au-it"
  }, {
    path: "/au-it/Page",
    component: _08b2ebf1,
    name: "Page___au-it"
  }, {
    path: "/au-it/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___au-it"
  }, {
    path: "/au-it/Product",
    component: _4bba9d7d,
    name: "Product___au-it"
  }, {
    path: "/au-it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___au-it"
  }, {
    path: "/au-it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___au-it"
  }, {
    path: "/au-it/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___au-it"
  }, {
    path: "/au-it/Route",
    component: _34cba952,
    name: "Route___au-it"
  }, {
    path: "/au-it/Search",
    component: _6f8b500a,
    name: "Search___au-it"
  }, {
    path: "/au-it/search-result",
    component: _6f8b500a,
    name: "search-result___au-it"
  }, {
    path: "/au-it/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___au-it"
  }, {
    path: "/au-it/work-with-us",
    component: _5dc06952,
    name: "work-with-us___au-it"
  }, {
    path: "/au-it/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___au-it"
  }, {
    path: "/bh-en/boutiques",
    component: _34cef09d,
    name: "boutiques___bh-en",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___bh-en"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___bh-en"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___bh-en"
    }]
  }, {
    path: "/bh-en/Boutiques",
    component: _34cef09d,
    name: "Boutiques___bh-en"
  }, {
    path: "/bh-en/Cart",
    component: _544757fc,
    name: "Cart___bh-en"
  }, {
    path: "/bh-en/Category",
    component: _0b2841e0,
    name: "Category___bh-en"
  }, {
    path: "/bh-en/checkout",
    component: _48ef6e28,
    name: "checkout___bh-en",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___bh-en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___bh-en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___bh-en"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___bh-en"
    }]
  }, {
    path: "/bh-en/Checkout",
    component: _48ef6e28,
    name: "Checkout___bh-en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___bh-en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___bh-en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___bh-en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___bh-en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___bh-en"
    }]
  }, {
    path: "/bh-en/Contact",
    component: _b1da17a4,
    name: "Contact___bh-en"
  }, {
    path: "/bh-en/contacts",
    component: _b1da17a4,
    name: "contacts___bh-en"
  }, {
    path: "/bh-en/contatti",
    component: _b1da17a4,
    name: "contatti___bh-en"
  }, {
    path: "/bh-en/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___bh-en"
  }, {
    path: "/bh-en/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___bh-en"
  }, {
    path: "/bh-en/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___bh-en"
  }, {
    path: "/bh-en/Journal",
    component: _b971c876,
    name: "Journal___bh-en"
  }, {
    path: "/bh-en/JournalView",
    component: _bf42fcec,
    name: "JournalView___bh-en"
  }, {
    path: "/bh-en/MyAccount",
    component: _10189cef,
    name: "MyAccount___bh-en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___bh-en"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___bh-en"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___bh-en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___bh-en"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___bh-en"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___bh-en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___bh-en"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___bh-en"
    }]
  }, {
    path: "/bh-en/newsletter",
    component: _a85becca,
    name: "newsletter___bh-en"
  }, {
    path: "/bh-en/Newsletter",
    component: _a85becca,
    name: "Newsletter___bh-en"
  }, {
    path: "/bh-en/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___bh-en"
  }, {
    path: "/bh-en/Page",
    component: _08b2ebf1,
    name: "Page___bh-en"
  }, {
    path: "/bh-en/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___bh-en"
  }, {
    path: "/bh-en/Product",
    component: _4bba9d7d,
    name: "Product___bh-en"
  }, {
    path: "/bh-en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___bh-en"
  }, {
    path: "/bh-en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___bh-en"
  }, {
    path: "/bh-en/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___bh-en"
  }, {
    path: "/bh-en/Route",
    component: _34cba952,
    name: "Route___bh-en"
  }, {
    path: "/bh-en/Search",
    component: _6f8b500a,
    name: "Search___bh-en"
  }, {
    path: "/bh-en/search-result",
    component: _6f8b500a,
    name: "search-result___bh-en"
  }, {
    path: "/bh-en/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___bh-en"
  }, {
    path: "/bh-en/work-with-us",
    component: _5dc06952,
    name: "work-with-us___bh-en"
  }, {
    path: "/bh-en/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___bh-en"
  }, {
    path: "/bh-it/boutiques",
    component: _34cef09d,
    name: "boutiques___bh-it",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___bh-it"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___bh-it"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___bh-it"
    }]
  }, {
    path: "/bh-it/Boutiques",
    component: _34cef09d,
    name: "Boutiques___bh-it"
  }, {
    path: "/bh-it/Cart",
    component: _544757fc,
    name: "Cart___bh-it"
  }, {
    path: "/bh-it/Category",
    component: _0b2841e0,
    name: "Category___bh-it"
  }, {
    path: "/bh-it/checkout",
    component: _48ef6e28,
    name: "checkout___bh-it",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___bh-it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___bh-it"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___bh-it"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___bh-it"
    }]
  }, {
    path: "/bh-it/Checkout",
    component: _48ef6e28,
    name: "Checkout___bh-it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___bh-it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___bh-it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___bh-it"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___bh-it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___bh-it"
    }]
  }, {
    path: "/bh-it/Contact",
    component: _b1da17a4,
    name: "Contact___bh-it"
  }, {
    path: "/bh-it/contacts",
    component: _b1da17a4,
    name: "contacts___bh-it"
  }, {
    path: "/bh-it/contatti",
    component: _b1da17a4,
    name: "contatti___bh-it"
  }, {
    path: "/bh-it/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___bh-it"
  }, {
    path: "/bh-it/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___bh-it"
  }, {
    path: "/bh-it/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___bh-it"
  }, {
    path: "/bh-it/Journal",
    component: _b971c876,
    name: "Journal___bh-it"
  }, {
    path: "/bh-it/JournalView",
    component: _bf42fcec,
    name: "JournalView___bh-it"
  }, {
    path: "/bh-it/MyAccount",
    component: _10189cef,
    name: "MyAccount___bh-it",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___bh-it"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___bh-it"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___bh-it"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___bh-it"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___bh-it"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___bh-it"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___bh-it"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___bh-it"
    }]
  }, {
    path: "/bh-it/newsletter",
    component: _a85becca,
    name: "newsletter___bh-it"
  }, {
    path: "/bh-it/Newsletter",
    component: _a85becca,
    name: "Newsletter___bh-it"
  }, {
    path: "/bh-it/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___bh-it"
  }, {
    path: "/bh-it/Page",
    component: _08b2ebf1,
    name: "Page___bh-it"
  }, {
    path: "/bh-it/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___bh-it"
  }, {
    path: "/bh-it/Product",
    component: _4bba9d7d,
    name: "Product___bh-it"
  }, {
    path: "/bh-it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___bh-it"
  }, {
    path: "/bh-it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___bh-it"
  }, {
    path: "/bh-it/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___bh-it"
  }, {
    path: "/bh-it/Route",
    component: _34cba952,
    name: "Route___bh-it"
  }, {
    path: "/bh-it/Search",
    component: _6f8b500a,
    name: "Search___bh-it"
  }, {
    path: "/bh-it/search-result",
    component: _6f8b500a,
    name: "search-result___bh-it"
  }, {
    path: "/bh-it/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___bh-it"
  }, {
    path: "/bh-it/work-with-us",
    component: _5dc06952,
    name: "work-with-us___bh-it"
  }, {
    path: "/bh-it/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___bh-it"
  }, {
    path: "/ca-en/boutiques",
    component: _34cef09d,
    name: "boutiques___ca-en",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___ca-en"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___ca-en"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___ca-en"
    }]
  }, {
    path: "/ca-en/Boutiques",
    component: _34cef09d,
    name: "Boutiques___ca-en"
  }, {
    path: "/ca-en/Cart",
    component: _544757fc,
    name: "Cart___ca-en"
  }, {
    path: "/ca-en/Category",
    component: _0b2841e0,
    name: "Category___ca-en"
  }, {
    path: "/ca-en/checkout",
    component: _48ef6e28,
    name: "checkout___ca-en",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___ca-en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___ca-en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___ca-en"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___ca-en"
    }]
  }, {
    path: "/ca-en/Checkout",
    component: _48ef6e28,
    name: "Checkout___ca-en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___ca-en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___ca-en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___ca-en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___ca-en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___ca-en"
    }]
  }, {
    path: "/ca-en/Contact",
    component: _b1da17a4,
    name: "Contact___ca-en"
  }, {
    path: "/ca-en/contacts",
    component: _b1da17a4,
    name: "contacts___ca-en"
  }, {
    path: "/ca-en/contatti",
    component: _b1da17a4,
    name: "contatti___ca-en"
  }, {
    path: "/ca-en/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___ca-en"
  }, {
    path: "/ca-en/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___ca-en"
  }, {
    path: "/ca-en/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___ca-en"
  }, {
    path: "/ca-en/Journal",
    component: _b971c876,
    name: "Journal___ca-en"
  }, {
    path: "/ca-en/JournalView",
    component: _bf42fcec,
    name: "JournalView___ca-en"
  }, {
    path: "/ca-en/MyAccount",
    component: _10189cef,
    name: "MyAccount___ca-en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___ca-en"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___ca-en"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___ca-en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___ca-en"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___ca-en"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___ca-en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___ca-en"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___ca-en"
    }]
  }, {
    path: "/ca-en/newsletter",
    component: _a85becca,
    name: "newsletter___ca-en"
  }, {
    path: "/ca-en/Newsletter",
    component: _a85becca,
    name: "Newsletter___ca-en"
  }, {
    path: "/ca-en/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___ca-en"
  }, {
    path: "/ca-en/Page",
    component: _08b2ebf1,
    name: "Page___ca-en"
  }, {
    path: "/ca-en/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___ca-en"
  }, {
    path: "/ca-en/Product",
    component: _4bba9d7d,
    name: "Product___ca-en"
  }, {
    path: "/ca-en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___ca-en"
  }, {
    path: "/ca-en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___ca-en"
  }, {
    path: "/ca-en/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___ca-en"
  }, {
    path: "/ca-en/Route",
    component: _34cba952,
    name: "Route___ca-en"
  }, {
    path: "/ca-en/Search",
    component: _6f8b500a,
    name: "Search___ca-en"
  }, {
    path: "/ca-en/search-result",
    component: _6f8b500a,
    name: "search-result___ca-en"
  }, {
    path: "/ca-en/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___ca-en"
  }, {
    path: "/ca-en/work-with-us",
    component: _5dc06952,
    name: "work-with-us___ca-en"
  }, {
    path: "/ca-en/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___ca-en"
  }, {
    path: "/ca-it/boutiques",
    component: _34cef09d,
    name: "boutiques___ca-it",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___ca-it"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___ca-it"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___ca-it"
    }]
  }, {
    path: "/ca-it/Boutiques",
    component: _34cef09d,
    name: "Boutiques___ca-it"
  }, {
    path: "/ca-it/Cart",
    component: _544757fc,
    name: "Cart___ca-it"
  }, {
    path: "/ca-it/Category",
    component: _0b2841e0,
    name: "Category___ca-it"
  }, {
    path: "/ca-it/checkout",
    component: _48ef6e28,
    name: "checkout___ca-it",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___ca-it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___ca-it"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___ca-it"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___ca-it"
    }]
  }, {
    path: "/ca-it/Checkout",
    component: _48ef6e28,
    name: "Checkout___ca-it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___ca-it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___ca-it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___ca-it"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___ca-it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___ca-it"
    }]
  }, {
    path: "/ca-it/Contact",
    component: _b1da17a4,
    name: "Contact___ca-it"
  }, {
    path: "/ca-it/contacts",
    component: _b1da17a4,
    name: "contacts___ca-it"
  }, {
    path: "/ca-it/contatti",
    component: _b1da17a4,
    name: "contatti___ca-it"
  }, {
    path: "/ca-it/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___ca-it"
  }, {
    path: "/ca-it/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___ca-it"
  }, {
    path: "/ca-it/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___ca-it"
  }, {
    path: "/ca-it/Journal",
    component: _b971c876,
    name: "Journal___ca-it"
  }, {
    path: "/ca-it/JournalView",
    component: _bf42fcec,
    name: "JournalView___ca-it"
  }, {
    path: "/ca-it/MyAccount",
    component: _10189cef,
    name: "MyAccount___ca-it",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___ca-it"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___ca-it"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___ca-it"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___ca-it"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___ca-it"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___ca-it"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___ca-it"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___ca-it"
    }]
  }, {
    path: "/ca-it/newsletter",
    component: _a85becca,
    name: "newsletter___ca-it"
  }, {
    path: "/ca-it/Newsletter",
    component: _a85becca,
    name: "Newsletter___ca-it"
  }, {
    path: "/ca-it/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___ca-it"
  }, {
    path: "/ca-it/Page",
    component: _08b2ebf1,
    name: "Page___ca-it"
  }, {
    path: "/ca-it/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___ca-it"
  }, {
    path: "/ca-it/Product",
    component: _4bba9d7d,
    name: "Product___ca-it"
  }, {
    path: "/ca-it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___ca-it"
  }, {
    path: "/ca-it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___ca-it"
  }, {
    path: "/ca-it/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___ca-it"
  }, {
    path: "/ca-it/Route",
    component: _34cba952,
    name: "Route___ca-it"
  }, {
    path: "/ca-it/Search",
    component: _6f8b500a,
    name: "Search___ca-it"
  }, {
    path: "/ca-it/search-result",
    component: _6f8b500a,
    name: "search-result___ca-it"
  }, {
    path: "/ca-it/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___ca-it"
  }, {
    path: "/ca-it/work-with-us",
    component: _5dc06952,
    name: "work-with-us___ca-it"
  }, {
    path: "/ca-it/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___ca-it"
  }, {
    path: "/ch-en/boutiques",
    component: _34cef09d,
    name: "boutiques___ch-en",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___ch-en"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___ch-en"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___ch-en"
    }]
  }, {
    path: "/ch-en/Boutiques",
    component: _34cef09d,
    name: "Boutiques___ch-en"
  }, {
    path: "/ch-en/Cart",
    component: _544757fc,
    name: "Cart___ch-en"
  }, {
    path: "/ch-en/Category",
    component: _0b2841e0,
    name: "Category___ch-en"
  }, {
    path: "/ch-en/checkout",
    component: _48ef6e28,
    name: "checkout___ch-en",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___ch-en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___ch-en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___ch-en"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___ch-en"
    }]
  }, {
    path: "/ch-en/Checkout",
    component: _48ef6e28,
    name: "Checkout___ch-en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___ch-en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___ch-en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___ch-en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___ch-en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___ch-en"
    }]
  }, {
    path: "/ch-en/Contact",
    component: _b1da17a4,
    name: "Contact___ch-en"
  }, {
    path: "/ch-en/contacts",
    component: _b1da17a4,
    name: "contacts___ch-en"
  }, {
    path: "/ch-en/contatti",
    component: _b1da17a4,
    name: "contatti___ch-en"
  }, {
    path: "/ch-en/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___ch-en"
  }, {
    path: "/ch-en/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___ch-en"
  }, {
    path: "/ch-en/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___ch-en"
  }, {
    path: "/ch-en/Journal",
    component: _b971c876,
    name: "Journal___ch-en"
  }, {
    path: "/ch-en/JournalView",
    component: _bf42fcec,
    name: "JournalView___ch-en"
  }, {
    path: "/ch-en/MyAccount",
    component: _10189cef,
    name: "MyAccount___ch-en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___ch-en"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___ch-en"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___ch-en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___ch-en"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___ch-en"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___ch-en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___ch-en"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___ch-en"
    }]
  }, {
    path: "/ch-en/newsletter",
    component: _a85becca,
    name: "newsletter___ch-en"
  }, {
    path: "/ch-en/Newsletter",
    component: _a85becca,
    name: "Newsletter___ch-en"
  }, {
    path: "/ch-en/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___ch-en"
  }, {
    path: "/ch-en/Page",
    component: _08b2ebf1,
    name: "Page___ch-en"
  }, {
    path: "/ch-en/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___ch-en"
  }, {
    path: "/ch-en/Product",
    component: _4bba9d7d,
    name: "Product___ch-en"
  }, {
    path: "/ch-en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___ch-en"
  }, {
    path: "/ch-en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___ch-en"
  }, {
    path: "/ch-en/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___ch-en"
  }, {
    path: "/ch-en/Route",
    component: _34cba952,
    name: "Route___ch-en"
  }, {
    path: "/ch-en/Search",
    component: _6f8b500a,
    name: "Search___ch-en"
  }, {
    path: "/ch-en/search-result",
    component: _6f8b500a,
    name: "search-result___ch-en"
  }, {
    path: "/ch-en/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___ch-en"
  }, {
    path: "/ch-en/work-with-us",
    component: _5dc06952,
    name: "work-with-us___ch-en"
  }, {
    path: "/ch-en/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___ch-en"
  }, {
    path: "/ch-it/boutiques",
    component: _34cef09d,
    name: "boutiques___ch-it",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___ch-it"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___ch-it"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___ch-it"
    }]
  }, {
    path: "/ch-it/Boutiques",
    component: _34cef09d,
    name: "Boutiques___ch-it"
  }, {
    path: "/ch-it/Cart",
    component: _544757fc,
    name: "Cart___ch-it"
  }, {
    path: "/ch-it/Category",
    component: _0b2841e0,
    name: "Category___ch-it"
  }, {
    path: "/ch-it/checkout",
    component: _48ef6e28,
    name: "checkout___ch-it",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___ch-it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___ch-it"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___ch-it"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___ch-it"
    }]
  }, {
    path: "/ch-it/Checkout",
    component: _48ef6e28,
    name: "Checkout___ch-it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___ch-it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___ch-it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___ch-it"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___ch-it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___ch-it"
    }]
  }, {
    path: "/ch-it/Contact",
    component: _b1da17a4,
    name: "Contact___ch-it"
  }, {
    path: "/ch-it/contacts",
    component: _b1da17a4,
    name: "contacts___ch-it"
  }, {
    path: "/ch-it/contatti",
    component: _b1da17a4,
    name: "contatti___ch-it"
  }, {
    path: "/ch-it/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___ch-it"
  }, {
    path: "/ch-it/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___ch-it"
  }, {
    path: "/ch-it/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___ch-it"
  }, {
    path: "/ch-it/Journal",
    component: _b971c876,
    name: "Journal___ch-it"
  }, {
    path: "/ch-it/JournalView",
    component: _bf42fcec,
    name: "JournalView___ch-it"
  }, {
    path: "/ch-it/MyAccount",
    component: _10189cef,
    name: "MyAccount___ch-it",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___ch-it"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___ch-it"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___ch-it"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___ch-it"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___ch-it"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___ch-it"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___ch-it"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___ch-it"
    }]
  }, {
    path: "/ch-it/newsletter",
    component: _a85becca,
    name: "newsletter___ch-it"
  }, {
    path: "/ch-it/Newsletter",
    component: _a85becca,
    name: "Newsletter___ch-it"
  }, {
    path: "/ch-it/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___ch-it"
  }, {
    path: "/ch-it/Page",
    component: _08b2ebf1,
    name: "Page___ch-it"
  }, {
    path: "/ch-it/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___ch-it"
  }, {
    path: "/ch-it/Product",
    component: _4bba9d7d,
    name: "Product___ch-it"
  }, {
    path: "/ch-it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___ch-it"
  }, {
    path: "/ch-it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___ch-it"
  }, {
    path: "/ch-it/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___ch-it"
  }, {
    path: "/ch-it/Route",
    component: _34cba952,
    name: "Route___ch-it"
  }, {
    path: "/ch-it/Search",
    component: _6f8b500a,
    name: "Search___ch-it"
  }, {
    path: "/ch-it/search-result",
    component: _6f8b500a,
    name: "search-result___ch-it"
  }, {
    path: "/ch-it/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___ch-it"
  }, {
    path: "/ch-it/work-with-us",
    component: _5dc06952,
    name: "work-with-us___ch-it"
  }, {
    path: "/ch-it/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___ch-it"
  }, {
    path: "/cn-en/boutiques",
    component: _34cef09d,
    name: "boutiques___cn-en",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___cn-en"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___cn-en"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___cn-en"
    }]
  }, {
    path: "/cn-en/Boutiques",
    component: _34cef09d,
    name: "Boutiques___cn-en"
  }, {
    path: "/cn-en/Cart",
    component: _544757fc,
    name: "Cart___cn-en"
  }, {
    path: "/cn-en/Category",
    component: _0b2841e0,
    name: "Category___cn-en"
  }, {
    path: "/cn-en/checkout",
    component: _48ef6e28,
    name: "checkout___cn-en",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___cn-en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___cn-en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___cn-en"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___cn-en"
    }]
  }, {
    path: "/cn-en/Checkout",
    component: _48ef6e28,
    name: "Checkout___cn-en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___cn-en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___cn-en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___cn-en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___cn-en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___cn-en"
    }]
  }, {
    path: "/cn-en/Contact",
    component: _b1da17a4,
    name: "Contact___cn-en"
  }, {
    path: "/cn-en/contacts",
    component: _b1da17a4,
    name: "contacts___cn-en"
  }, {
    path: "/cn-en/contatti",
    component: _b1da17a4,
    name: "contatti___cn-en"
  }, {
    path: "/cn-en/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___cn-en"
  }, {
    path: "/cn-en/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___cn-en"
  }, {
    path: "/cn-en/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___cn-en"
  }, {
    path: "/cn-en/Journal",
    component: _b971c876,
    name: "Journal___cn-en"
  }, {
    path: "/cn-en/JournalView",
    component: _bf42fcec,
    name: "JournalView___cn-en"
  }, {
    path: "/cn-en/MyAccount",
    component: _10189cef,
    name: "MyAccount___cn-en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___cn-en"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___cn-en"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___cn-en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___cn-en"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___cn-en"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___cn-en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___cn-en"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___cn-en"
    }]
  }, {
    path: "/cn-en/newsletter",
    component: _a85becca,
    name: "newsletter___cn-en"
  }, {
    path: "/cn-en/Newsletter",
    component: _a85becca,
    name: "Newsletter___cn-en"
  }, {
    path: "/cn-en/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___cn-en"
  }, {
    path: "/cn-en/Page",
    component: _08b2ebf1,
    name: "Page___cn-en"
  }, {
    path: "/cn-en/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___cn-en"
  }, {
    path: "/cn-en/Product",
    component: _4bba9d7d,
    name: "Product___cn-en"
  }, {
    path: "/cn-en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___cn-en"
  }, {
    path: "/cn-en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___cn-en"
  }, {
    path: "/cn-en/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___cn-en"
  }, {
    path: "/cn-en/Route",
    component: _34cba952,
    name: "Route___cn-en"
  }, {
    path: "/cn-en/Search",
    component: _6f8b500a,
    name: "Search___cn-en"
  }, {
    path: "/cn-en/search-result",
    component: _6f8b500a,
    name: "search-result___cn-en"
  }, {
    path: "/cn-en/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___cn-en"
  }, {
    path: "/cn-en/work-with-us",
    component: _5dc06952,
    name: "work-with-us___cn-en"
  }, {
    path: "/cn-en/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___cn-en"
  }, {
    path: "/cn-it/boutiques",
    component: _34cef09d,
    name: "boutiques___cn-it",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___cn-it"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___cn-it"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___cn-it"
    }]
  }, {
    path: "/cn-it/Boutiques",
    component: _34cef09d,
    name: "Boutiques___cn-it"
  }, {
    path: "/cn-it/Cart",
    component: _544757fc,
    name: "Cart___cn-it"
  }, {
    path: "/cn-it/Category",
    component: _0b2841e0,
    name: "Category___cn-it"
  }, {
    path: "/cn-it/checkout",
    component: _48ef6e28,
    name: "checkout___cn-it",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___cn-it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___cn-it"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___cn-it"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___cn-it"
    }]
  }, {
    path: "/cn-it/Checkout",
    component: _48ef6e28,
    name: "Checkout___cn-it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___cn-it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___cn-it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___cn-it"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___cn-it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___cn-it"
    }]
  }, {
    path: "/cn-it/Contact",
    component: _b1da17a4,
    name: "Contact___cn-it"
  }, {
    path: "/cn-it/contacts",
    component: _b1da17a4,
    name: "contacts___cn-it"
  }, {
    path: "/cn-it/contatti",
    component: _b1da17a4,
    name: "contatti___cn-it"
  }, {
    path: "/cn-it/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___cn-it"
  }, {
    path: "/cn-it/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___cn-it"
  }, {
    path: "/cn-it/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___cn-it"
  }, {
    path: "/cn-it/Journal",
    component: _b971c876,
    name: "Journal___cn-it"
  }, {
    path: "/cn-it/JournalView",
    component: _bf42fcec,
    name: "JournalView___cn-it"
  }, {
    path: "/cn-it/MyAccount",
    component: _10189cef,
    name: "MyAccount___cn-it",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___cn-it"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___cn-it"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___cn-it"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___cn-it"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___cn-it"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___cn-it"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___cn-it"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___cn-it"
    }]
  }, {
    path: "/cn-it/newsletter",
    component: _a85becca,
    name: "newsletter___cn-it"
  }, {
    path: "/cn-it/Newsletter",
    component: _a85becca,
    name: "Newsletter___cn-it"
  }, {
    path: "/cn-it/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___cn-it"
  }, {
    path: "/cn-it/Page",
    component: _08b2ebf1,
    name: "Page___cn-it"
  }, {
    path: "/cn-it/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___cn-it"
  }, {
    path: "/cn-it/Product",
    component: _4bba9d7d,
    name: "Product___cn-it"
  }, {
    path: "/cn-it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___cn-it"
  }, {
    path: "/cn-it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___cn-it"
  }, {
    path: "/cn-it/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___cn-it"
  }, {
    path: "/cn-it/Route",
    component: _34cba952,
    name: "Route___cn-it"
  }, {
    path: "/cn-it/Search",
    component: _6f8b500a,
    name: "Search___cn-it"
  }, {
    path: "/cn-it/search-result",
    component: _6f8b500a,
    name: "search-result___cn-it"
  }, {
    path: "/cn-it/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___cn-it"
  }, {
    path: "/cn-it/work-with-us",
    component: _5dc06952,
    name: "work-with-us___cn-it"
  }, {
    path: "/cn-it/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___cn-it"
  }, {
    path: "/de-en/boutiques",
    component: _34cef09d,
    name: "boutiques___de-en",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___de-en"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___de-en"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___de-en"
    }]
  }, {
    path: "/de-en/Boutiques",
    component: _34cef09d,
    name: "Boutiques___de-en"
  }, {
    path: "/de-en/Cart",
    component: _544757fc,
    name: "Cart___de-en"
  }, {
    path: "/de-en/Category",
    component: _0b2841e0,
    name: "Category___de-en"
  }, {
    path: "/de-en/checkout",
    component: _48ef6e28,
    name: "checkout___de-en",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___de-en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___de-en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___de-en"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___de-en"
    }]
  }, {
    path: "/de-en/Checkout",
    component: _48ef6e28,
    name: "Checkout___de-en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___de-en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___de-en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___de-en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___de-en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___de-en"
    }]
  }, {
    path: "/de-en/Contact",
    component: _b1da17a4,
    name: "Contact___de-en"
  }, {
    path: "/de-en/contacts",
    component: _b1da17a4,
    name: "contacts___de-en"
  }, {
    path: "/de-en/contatti",
    component: _b1da17a4,
    name: "contatti___de-en"
  }, {
    path: "/de-en/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___de-en"
  }, {
    path: "/de-en/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___de-en"
  }, {
    path: "/de-en/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___de-en"
  }, {
    path: "/de-en/Journal",
    component: _b971c876,
    name: "Journal___de-en"
  }, {
    path: "/de-en/JournalView",
    component: _bf42fcec,
    name: "JournalView___de-en"
  }, {
    path: "/de-en/MyAccount",
    component: _10189cef,
    name: "MyAccount___de-en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___de-en"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___de-en"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___de-en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___de-en"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___de-en"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___de-en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___de-en"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___de-en"
    }]
  }, {
    path: "/de-en/newsletter",
    component: _a85becca,
    name: "newsletter___de-en"
  }, {
    path: "/de-en/Newsletter",
    component: _a85becca,
    name: "Newsletter___de-en"
  }, {
    path: "/de-en/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___de-en"
  }, {
    path: "/de-en/Page",
    component: _08b2ebf1,
    name: "Page___de-en"
  }, {
    path: "/de-en/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___de-en"
  }, {
    path: "/de-en/Product",
    component: _4bba9d7d,
    name: "Product___de-en"
  }, {
    path: "/de-en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___de-en"
  }, {
    path: "/de-en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___de-en"
  }, {
    path: "/de-en/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___de-en"
  }, {
    path: "/de-en/Route",
    component: _34cba952,
    name: "Route___de-en"
  }, {
    path: "/de-en/Search",
    component: _6f8b500a,
    name: "Search___de-en"
  }, {
    path: "/de-en/search-result",
    component: _6f8b500a,
    name: "search-result___de-en"
  }, {
    path: "/de-en/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___de-en"
  }, {
    path: "/de-en/work-with-us",
    component: _5dc06952,
    name: "work-with-us___de-en"
  }, {
    path: "/de-en/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___de-en"
  }, {
    path: "/de-it/boutiques",
    component: _34cef09d,
    name: "boutiques___de-it",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___de-it"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___de-it"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___de-it"
    }]
  }, {
    path: "/de-it/Boutiques",
    component: _34cef09d,
    name: "Boutiques___de-it"
  }, {
    path: "/de-it/Cart",
    component: _544757fc,
    name: "Cart___de-it"
  }, {
    path: "/de-it/Category",
    component: _0b2841e0,
    name: "Category___de-it"
  }, {
    path: "/de-it/checkout",
    component: _48ef6e28,
    name: "checkout___de-it",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___de-it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___de-it"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___de-it"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___de-it"
    }]
  }, {
    path: "/de-it/Checkout",
    component: _48ef6e28,
    name: "Checkout___de-it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___de-it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___de-it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___de-it"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___de-it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___de-it"
    }]
  }, {
    path: "/de-it/Contact",
    component: _b1da17a4,
    name: "Contact___de-it"
  }, {
    path: "/de-it/contacts",
    component: _b1da17a4,
    name: "contacts___de-it"
  }, {
    path: "/de-it/contatti",
    component: _b1da17a4,
    name: "contatti___de-it"
  }, {
    path: "/de-it/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___de-it"
  }, {
    path: "/de-it/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___de-it"
  }, {
    path: "/de-it/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___de-it"
  }, {
    path: "/de-it/Journal",
    component: _b971c876,
    name: "Journal___de-it"
  }, {
    path: "/de-it/JournalView",
    component: _bf42fcec,
    name: "JournalView___de-it"
  }, {
    path: "/de-it/MyAccount",
    component: _10189cef,
    name: "MyAccount___de-it",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___de-it"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___de-it"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___de-it"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___de-it"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___de-it"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___de-it"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___de-it"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___de-it"
    }]
  }, {
    path: "/de-it/newsletter",
    component: _a85becca,
    name: "newsletter___de-it"
  }, {
    path: "/de-it/Newsletter",
    component: _a85becca,
    name: "Newsletter___de-it"
  }, {
    path: "/de-it/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___de-it"
  }, {
    path: "/de-it/Page",
    component: _08b2ebf1,
    name: "Page___de-it"
  }, {
    path: "/de-it/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___de-it"
  }, {
    path: "/de-it/Product",
    component: _4bba9d7d,
    name: "Product___de-it"
  }, {
    path: "/de-it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___de-it"
  }, {
    path: "/de-it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___de-it"
  }, {
    path: "/de-it/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___de-it"
  }, {
    path: "/de-it/Route",
    component: _34cba952,
    name: "Route___de-it"
  }, {
    path: "/de-it/Search",
    component: _6f8b500a,
    name: "Search___de-it"
  }, {
    path: "/de-it/search-result",
    component: _6f8b500a,
    name: "search-result___de-it"
  }, {
    path: "/de-it/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___de-it"
  }, {
    path: "/de-it/work-with-us",
    component: _5dc06952,
    name: "work-with-us___de-it"
  }, {
    path: "/de-it/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___de-it"
  }, {
    path: "/eue-en/boutiques",
    component: _34cef09d,
    name: "boutiques___eue-en",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___eue-en"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___eue-en"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___eue-en"
    }]
  }, {
    path: "/eue-en/Boutiques",
    component: _34cef09d,
    name: "Boutiques___eue-en"
  }, {
    path: "/eue-en/Cart",
    component: _544757fc,
    name: "Cart___eue-en"
  }, {
    path: "/eue-en/Category",
    component: _0b2841e0,
    name: "Category___eue-en"
  }, {
    path: "/eue-en/checkout",
    component: _48ef6e28,
    name: "checkout___eue-en",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___eue-en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___eue-en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___eue-en"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___eue-en"
    }]
  }, {
    path: "/eue-en/Checkout",
    component: _48ef6e28,
    name: "Checkout___eue-en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___eue-en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___eue-en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___eue-en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___eue-en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___eue-en"
    }]
  }, {
    path: "/eue-en/Contact",
    component: _b1da17a4,
    name: "Contact___eue-en"
  }, {
    path: "/eue-en/contacts",
    component: _b1da17a4,
    name: "contacts___eue-en"
  }, {
    path: "/eue-en/contatti",
    component: _b1da17a4,
    name: "contatti___eue-en"
  }, {
    path: "/eue-en/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___eue-en"
  }, {
    path: "/eue-en/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___eue-en"
  }, {
    path: "/eue-en/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___eue-en"
  }, {
    path: "/eue-en/Journal",
    component: _b971c876,
    name: "Journal___eue-en"
  }, {
    path: "/eue-en/JournalView",
    component: _bf42fcec,
    name: "JournalView___eue-en"
  }, {
    path: "/eue-en/MyAccount",
    component: _10189cef,
    name: "MyAccount___eue-en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___eue-en"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___eue-en"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___eue-en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___eue-en"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___eue-en"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___eue-en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___eue-en"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___eue-en"
    }]
  }, {
    path: "/eue-en/newsletter",
    component: _a85becca,
    name: "newsletter___eue-en"
  }, {
    path: "/eue-en/Newsletter",
    component: _a85becca,
    name: "Newsletter___eue-en"
  }, {
    path: "/eue-en/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___eue-en"
  }, {
    path: "/eue-en/Page",
    component: _08b2ebf1,
    name: "Page___eue-en"
  }, {
    path: "/eue-en/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___eue-en"
  }, {
    path: "/eue-en/Product",
    component: _4bba9d7d,
    name: "Product___eue-en"
  }, {
    path: "/eue-en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___eue-en"
  }, {
    path: "/eue-en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___eue-en"
  }, {
    path: "/eue-en/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___eue-en"
  }, {
    path: "/eue-en/Route",
    component: _34cba952,
    name: "Route___eue-en"
  }, {
    path: "/eue-en/Search",
    component: _6f8b500a,
    name: "Search___eue-en"
  }, {
    path: "/eue-en/search-result",
    component: _6f8b500a,
    name: "search-result___eue-en"
  }, {
    path: "/eue-en/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___eue-en"
  }, {
    path: "/eue-en/work-with-us",
    component: _5dc06952,
    name: "work-with-us___eue-en"
  }, {
    path: "/eue-en/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___eue-en"
  }, {
    path: "/eue-it/boutiques",
    component: _34cef09d,
    name: "boutiques___eue-it",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___eue-it"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___eue-it"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___eue-it"
    }]
  }, {
    path: "/eue-it/Boutiques",
    component: _34cef09d,
    name: "Boutiques___eue-it"
  }, {
    path: "/eue-it/Cart",
    component: _544757fc,
    name: "Cart___eue-it"
  }, {
    path: "/eue-it/Category",
    component: _0b2841e0,
    name: "Category___eue-it"
  }, {
    path: "/eue-it/checkout",
    component: _48ef6e28,
    name: "checkout___eue-it",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___eue-it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___eue-it"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___eue-it"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___eue-it"
    }]
  }, {
    path: "/eue-it/Checkout",
    component: _48ef6e28,
    name: "Checkout___eue-it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___eue-it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___eue-it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___eue-it"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___eue-it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___eue-it"
    }]
  }, {
    path: "/eue-it/Contact",
    component: _b1da17a4,
    name: "Contact___eue-it"
  }, {
    path: "/eue-it/contacts",
    component: _b1da17a4,
    name: "contacts___eue-it"
  }, {
    path: "/eue-it/contatti",
    component: _b1da17a4,
    name: "contatti___eue-it"
  }, {
    path: "/eue-it/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___eue-it"
  }, {
    path: "/eue-it/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___eue-it"
  }, {
    path: "/eue-it/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___eue-it"
  }, {
    path: "/eue-it/Journal",
    component: _b971c876,
    name: "Journal___eue-it"
  }, {
    path: "/eue-it/JournalView",
    component: _bf42fcec,
    name: "JournalView___eue-it"
  }, {
    path: "/eue-it/MyAccount",
    component: _10189cef,
    name: "MyAccount___eue-it",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___eue-it"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___eue-it"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___eue-it"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___eue-it"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___eue-it"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___eue-it"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___eue-it"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___eue-it"
    }]
  }, {
    path: "/eue-it/newsletter",
    component: _a85becca,
    name: "newsletter___eue-it"
  }, {
    path: "/eue-it/Newsletter",
    component: _a85becca,
    name: "Newsletter___eue-it"
  }, {
    path: "/eue-it/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___eue-it"
  }, {
    path: "/eue-it/Page",
    component: _08b2ebf1,
    name: "Page___eue-it"
  }, {
    path: "/eue-it/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___eue-it"
  }, {
    path: "/eue-it/Product",
    component: _4bba9d7d,
    name: "Product___eue-it"
  }, {
    path: "/eue-it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___eue-it"
  }, {
    path: "/eue-it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___eue-it"
  }, {
    path: "/eue-it/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___eue-it"
  }, {
    path: "/eue-it/Route",
    component: _34cba952,
    name: "Route___eue-it"
  }, {
    path: "/eue-it/Search",
    component: _6f8b500a,
    name: "Search___eue-it"
  }, {
    path: "/eue-it/search-result",
    component: _6f8b500a,
    name: "search-result___eue-it"
  }, {
    path: "/eue-it/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___eue-it"
  }, {
    path: "/eue-it/work-with-us",
    component: _5dc06952,
    name: "work-with-us___eue-it"
  }, {
    path: "/eue-it/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___eue-it"
  }, {
    path: "/gb-en/boutiques",
    component: _34cef09d,
    name: "boutiques___gb-en",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___gb-en"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___gb-en"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___gb-en"
    }]
  }, {
    path: "/gb-en/Boutiques",
    component: _34cef09d,
    name: "Boutiques___gb-en"
  }, {
    path: "/gb-en/Cart",
    component: _544757fc,
    name: "Cart___gb-en"
  }, {
    path: "/gb-en/Category",
    component: _0b2841e0,
    name: "Category___gb-en"
  }, {
    path: "/gb-en/checkout",
    component: _48ef6e28,
    name: "checkout___gb-en",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___gb-en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___gb-en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___gb-en"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___gb-en"
    }]
  }, {
    path: "/gb-en/Checkout",
    component: _48ef6e28,
    name: "Checkout___gb-en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___gb-en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___gb-en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___gb-en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___gb-en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___gb-en"
    }]
  }, {
    path: "/gb-en/Contact",
    component: _b1da17a4,
    name: "Contact___gb-en"
  }, {
    path: "/gb-en/contacts",
    component: _b1da17a4,
    name: "contacts___gb-en"
  }, {
    path: "/gb-en/contatti",
    component: _b1da17a4,
    name: "contatti___gb-en"
  }, {
    path: "/gb-en/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___gb-en"
  }, {
    path: "/gb-en/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___gb-en"
  }, {
    path: "/gb-en/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___gb-en"
  }, {
    path: "/gb-en/Journal",
    component: _b971c876,
    name: "Journal___gb-en"
  }, {
    path: "/gb-en/JournalView",
    component: _bf42fcec,
    name: "JournalView___gb-en"
  }, {
    path: "/gb-en/MyAccount",
    component: _10189cef,
    name: "MyAccount___gb-en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___gb-en"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___gb-en"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___gb-en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___gb-en"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___gb-en"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___gb-en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___gb-en"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___gb-en"
    }]
  }, {
    path: "/gb-en/newsletter",
    component: _a85becca,
    name: "newsletter___gb-en"
  }, {
    path: "/gb-en/Newsletter",
    component: _a85becca,
    name: "Newsletter___gb-en"
  }, {
    path: "/gb-en/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___gb-en"
  }, {
    path: "/gb-en/Page",
    component: _08b2ebf1,
    name: "Page___gb-en"
  }, {
    path: "/gb-en/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___gb-en"
  }, {
    path: "/gb-en/Product",
    component: _4bba9d7d,
    name: "Product___gb-en"
  }, {
    path: "/gb-en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___gb-en"
  }, {
    path: "/gb-en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___gb-en"
  }, {
    path: "/gb-en/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___gb-en"
  }, {
    path: "/gb-en/Route",
    component: _34cba952,
    name: "Route___gb-en"
  }, {
    path: "/gb-en/Search",
    component: _6f8b500a,
    name: "Search___gb-en"
  }, {
    path: "/gb-en/search-result",
    component: _6f8b500a,
    name: "search-result___gb-en"
  }, {
    path: "/gb-en/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___gb-en"
  }, {
    path: "/gb-en/work-with-us",
    component: _5dc06952,
    name: "work-with-us___gb-en"
  }, {
    path: "/gb-en/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___gb-en"
  }, {
    path: "/gb-it/boutiques",
    component: _34cef09d,
    name: "boutiques___gb-it",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___gb-it"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___gb-it"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___gb-it"
    }]
  }, {
    path: "/gb-it/Boutiques",
    component: _34cef09d,
    name: "Boutiques___gb-it"
  }, {
    path: "/gb-it/Cart",
    component: _544757fc,
    name: "Cart___gb-it"
  }, {
    path: "/gb-it/Category",
    component: _0b2841e0,
    name: "Category___gb-it"
  }, {
    path: "/gb-it/checkout",
    component: _48ef6e28,
    name: "checkout___gb-it",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___gb-it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___gb-it"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___gb-it"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___gb-it"
    }]
  }, {
    path: "/gb-it/Checkout",
    component: _48ef6e28,
    name: "Checkout___gb-it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___gb-it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___gb-it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___gb-it"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___gb-it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___gb-it"
    }]
  }, {
    path: "/gb-it/Contact",
    component: _b1da17a4,
    name: "Contact___gb-it"
  }, {
    path: "/gb-it/contacts",
    component: _b1da17a4,
    name: "contacts___gb-it"
  }, {
    path: "/gb-it/contatti",
    component: _b1da17a4,
    name: "contatti___gb-it"
  }, {
    path: "/gb-it/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___gb-it"
  }, {
    path: "/gb-it/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___gb-it"
  }, {
    path: "/gb-it/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___gb-it"
  }, {
    path: "/gb-it/Journal",
    component: _b971c876,
    name: "Journal___gb-it"
  }, {
    path: "/gb-it/JournalView",
    component: _bf42fcec,
    name: "JournalView___gb-it"
  }, {
    path: "/gb-it/MyAccount",
    component: _10189cef,
    name: "MyAccount___gb-it",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___gb-it"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___gb-it"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___gb-it"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___gb-it"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___gb-it"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___gb-it"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___gb-it"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___gb-it"
    }]
  }, {
    path: "/gb-it/newsletter",
    component: _a85becca,
    name: "newsletter___gb-it"
  }, {
    path: "/gb-it/Newsletter",
    component: _a85becca,
    name: "Newsletter___gb-it"
  }, {
    path: "/gb-it/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___gb-it"
  }, {
    path: "/gb-it/Page",
    component: _08b2ebf1,
    name: "Page___gb-it"
  }, {
    path: "/gb-it/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___gb-it"
  }, {
    path: "/gb-it/Product",
    component: _4bba9d7d,
    name: "Product___gb-it"
  }, {
    path: "/gb-it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___gb-it"
  }, {
    path: "/gb-it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___gb-it"
  }, {
    path: "/gb-it/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___gb-it"
  }, {
    path: "/gb-it/Route",
    component: _34cba952,
    name: "Route___gb-it"
  }, {
    path: "/gb-it/Search",
    component: _6f8b500a,
    name: "Search___gb-it"
  }, {
    path: "/gb-it/search-result",
    component: _6f8b500a,
    name: "search-result___gb-it"
  }, {
    path: "/gb-it/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___gb-it"
  }, {
    path: "/gb-it/work-with-us",
    component: _5dc06952,
    name: "work-with-us___gb-it"
  }, {
    path: "/gb-it/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___gb-it"
  }, {
    path: "/hk-en/boutiques",
    component: _34cef09d,
    name: "boutiques___hk-en",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___hk-en"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___hk-en"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___hk-en"
    }]
  }, {
    path: "/hk-en/Boutiques",
    component: _34cef09d,
    name: "Boutiques___hk-en"
  }, {
    path: "/hk-en/Cart",
    component: _544757fc,
    name: "Cart___hk-en"
  }, {
    path: "/hk-en/Category",
    component: _0b2841e0,
    name: "Category___hk-en"
  }, {
    path: "/hk-en/checkout",
    component: _48ef6e28,
    name: "checkout___hk-en",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___hk-en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___hk-en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___hk-en"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___hk-en"
    }]
  }, {
    path: "/hk-en/Checkout",
    component: _48ef6e28,
    name: "Checkout___hk-en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___hk-en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___hk-en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___hk-en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___hk-en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___hk-en"
    }]
  }, {
    path: "/hk-en/Contact",
    component: _b1da17a4,
    name: "Contact___hk-en"
  }, {
    path: "/hk-en/contacts",
    component: _b1da17a4,
    name: "contacts___hk-en"
  }, {
    path: "/hk-en/contatti",
    component: _b1da17a4,
    name: "contatti___hk-en"
  }, {
    path: "/hk-en/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___hk-en"
  }, {
    path: "/hk-en/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___hk-en"
  }, {
    path: "/hk-en/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___hk-en"
  }, {
    path: "/hk-en/Journal",
    component: _b971c876,
    name: "Journal___hk-en"
  }, {
    path: "/hk-en/JournalView",
    component: _bf42fcec,
    name: "JournalView___hk-en"
  }, {
    path: "/hk-en/MyAccount",
    component: _10189cef,
    name: "MyAccount___hk-en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___hk-en"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___hk-en"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___hk-en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___hk-en"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___hk-en"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___hk-en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___hk-en"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___hk-en"
    }]
  }, {
    path: "/hk-en/newsletter",
    component: _a85becca,
    name: "newsletter___hk-en"
  }, {
    path: "/hk-en/Newsletter",
    component: _a85becca,
    name: "Newsletter___hk-en"
  }, {
    path: "/hk-en/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___hk-en"
  }, {
    path: "/hk-en/Page",
    component: _08b2ebf1,
    name: "Page___hk-en"
  }, {
    path: "/hk-en/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___hk-en"
  }, {
    path: "/hk-en/Product",
    component: _4bba9d7d,
    name: "Product___hk-en"
  }, {
    path: "/hk-en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___hk-en"
  }, {
    path: "/hk-en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___hk-en"
  }, {
    path: "/hk-en/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___hk-en"
  }, {
    path: "/hk-en/Route",
    component: _34cba952,
    name: "Route___hk-en"
  }, {
    path: "/hk-en/Search",
    component: _6f8b500a,
    name: "Search___hk-en"
  }, {
    path: "/hk-en/search-result",
    component: _6f8b500a,
    name: "search-result___hk-en"
  }, {
    path: "/hk-en/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___hk-en"
  }, {
    path: "/hk-en/work-with-us",
    component: _5dc06952,
    name: "work-with-us___hk-en"
  }, {
    path: "/hk-en/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___hk-en"
  }, {
    path: "/hk-it/boutiques",
    component: _34cef09d,
    name: "boutiques___hk-it",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___hk-it"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___hk-it"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___hk-it"
    }]
  }, {
    path: "/hk-it/Boutiques",
    component: _34cef09d,
    name: "Boutiques___hk-it"
  }, {
    path: "/hk-it/Cart",
    component: _544757fc,
    name: "Cart___hk-it"
  }, {
    path: "/hk-it/Category",
    component: _0b2841e0,
    name: "Category___hk-it"
  }, {
    path: "/hk-it/checkout",
    component: _48ef6e28,
    name: "checkout___hk-it",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___hk-it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___hk-it"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___hk-it"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___hk-it"
    }]
  }, {
    path: "/hk-it/Checkout",
    component: _48ef6e28,
    name: "Checkout___hk-it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___hk-it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___hk-it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___hk-it"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___hk-it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___hk-it"
    }]
  }, {
    path: "/hk-it/Contact",
    component: _b1da17a4,
    name: "Contact___hk-it"
  }, {
    path: "/hk-it/contacts",
    component: _b1da17a4,
    name: "contacts___hk-it"
  }, {
    path: "/hk-it/contatti",
    component: _b1da17a4,
    name: "contatti___hk-it"
  }, {
    path: "/hk-it/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___hk-it"
  }, {
    path: "/hk-it/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___hk-it"
  }, {
    path: "/hk-it/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___hk-it"
  }, {
    path: "/hk-it/Journal",
    component: _b971c876,
    name: "Journal___hk-it"
  }, {
    path: "/hk-it/JournalView",
    component: _bf42fcec,
    name: "JournalView___hk-it"
  }, {
    path: "/hk-it/MyAccount",
    component: _10189cef,
    name: "MyAccount___hk-it",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___hk-it"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___hk-it"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___hk-it"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___hk-it"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___hk-it"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___hk-it"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___hk-it"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___hk-it"
    }]
  }, {
    path: "/hk-it/newsletter",
    component: _a85becca,
    name: "newsletter___hk-it"
  }, {
    path: "/hk-it/Newsletter",
    component: _a85becca,
    name: "Newsletter___hk-it"
  }, {
    path: "/hk-it/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___hk-it"
  }, {
    path: "/hk-it/Page",
    component: _08b2ebf1,
    name: "Page___hk-it"
  }, {
    path: "/hk-it/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___hk-it"
  }, {
    path: "/hk-it/Product",
    component: _4bba9d7d,
    name: "Product___hk-it"
  }, {
    path: "/hk-it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___hk-it"
  }, {
    path: "/hk-it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___hk-it"
  }, {
    path: "/hk-it/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___hk-it"
  }, {
    path: "/hk-it/Route",
    component: _34cba952,
    name: "Route___hk-it"
  }, {
    path: "/hk-it/Search",
    component: _6f8b500a,
    name: "Search___hk-it"
  }, {
    path: "/hk-it/search-result",
    component: _6f8b500a,
    name: "search-result___hk-it"
  }, {
    path: "/hk-it/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___hk-it"
  }, {
    path: "/hk-it/work-with-us",
    component: _5dc06952,
    name: "work-with-us___hk-it"
  }, {
    path: "/hk-it/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___hk-it"
  }, {
    path: "/it-en/boutiques",
    component: _34cef09d,
    name: "boutiques___it-en",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___it-en"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___it-en"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___it-en"
    }]
  }, {
    path: "/it-en/Boutiques",
    component: _34cef09d,
    name: "Boutiques___it-en"
  }, {
    path: "/it-en/Cart",
    component: _544757fc,
    name: "Cart___it-en"
  }, {
    path: "/it-en/Category",
    component: _0b2841e0,
    name: "Category___it-en"
  }, {
    path: "/it-en/checkout",
    component: _48ef6e28,
    name: "checkout___it-en",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___it-en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___it-en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___it-en"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___it-en"
    }]
  }, {
    path: "/it-en/Checkout",
    component: _48ef6e28,
    name: "Checkout___it-en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___it-en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___it-en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___it-en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___it-en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___it-en"
    }]
  }, {
    path: "/it-en/Contact",
    component: _b1da17a4,
    name: "Contact___it-en"
  }, {
    path: "/it-en/contacts",
    component: _b1da17a4,
    name: "contacts___it-en"
  }, {
    path: "/it-en/contatti",
    component: _b1da17a4,
    name: "contatti___it-en"
  }, {
    path: "/it-en/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___it-en"
  }, {
    path: "/it-en/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___it-en"
  }, {
    path: "/it-en/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___it-en"
  }, {
    path: "/it-en/Journal",
    component: _b971c876,
    name: "Journal___it-en"
  }, {
    path: "/it-en/JournalView",
    component: _bf42fcec,
    name: "JournalView___it-en"
  }, {
    path: "/it-en/MyAccount",
    component: _10189cef,
    name: "MyAccount___it-en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___it-en"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___it-en"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___it-en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___it-en"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___it-en"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___it-en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___it-en"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___it-en"
    }]
  }, {
    path: "/it-en/newsletter",
    component: _a85becca,
    name: "newsletter___it-en"
  }, {
    path: "/it-en/Newsletter",
    component: _a85becca,
    name: "Newsletter___it-en"
  }, {
    path: "/it-en/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___it-en"
  }, {
    path: "/it-en/Page",
    component: _08b2ebf1,
    name: "Page___it-en"
  }, {
    path: "/it-en/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___it-en"
  }, {
    path: "/it-en/Product",
    component: _4bba9d7d,
    name: "Product___it-en"
  }, {
    path: "/it-en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___it-en"
  }, {
    path: "/it-en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___it-en"
  }, {
    path: "/it-en/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___it-en"
  }, {
    path: "/it-en/Route",
    component: _34cba952,
    name: "Route___it-en"
  }, {
    path: "/it-en/Search",
    component: _6f8b500a,
    name: "Search___it-en"
  }, {
    path: "/it-en/search-result",
    component: _6f8b500a,
    name: "search-result___it-en"
  }, {
    path: "/it-en/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___it-en"
  }, {
    path: "/it-en/work-with-us",
    component: _5dc06952,
    name: "work-with-us___it-en"
  }, {
    path: "/it-en/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___it-en"
  }, {
    path: "/it-it/boutiques",
    component: _34cef09d,
    name: "boutiques___it-it",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___it-it"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___it-it"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___it-it"
    }]
  }, {
    path: "/it-it/Boutiques",
    component: _34cef09d,
    name: "Boutiques___it-it"
  }, {
    path: "/it-it/Cart",
    component: _544757fc,
    name: "Cart___it-it"
  }, {
    path: "/it-it/Category",
    component: _0b2841e0,
    name: "Category___it-it"
  }, {
    path: "/it-it/checkout",
    component: _48ef6e28,
    name: "checkout___it-it",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___it-it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___it-it"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___it-it"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___it-it"
    }]
  }, {
    path: "/it-it/Checkout",
    component: _48ef6e28,
    name: "Checkout___it-it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___it-it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___it-it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___it-it"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___it-it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___it-it"
    }]
  }, {
    path: "/it-it/Contact",
    component: _b1da17a4,
    name: "Contact___it-it"
  }, {
    path: "/it-it/contacts",
    component: _b1da17a4,
    name: "contacts___it-it"
  }, {
    path: "/it-it/contatti",
    component: _b1da17a4,
    name: "contatti___it-it"
  }, {
    path: "/it-it/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___it-it"
  }, {
    path: "/it-it/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___it-it"
  }, {
    path: "/it-it/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___it-it"
  }, {
    path: "/it-it/Journal",
    component: _b971c876,
    name: "Journal___it-it"
  }, {
    path: "/it-it/JournalView",
    component: _bf42fcec,
    name: "JournalView___it-it"
  }, {
    path: "/it-it/MyAccount",
    component: _10189cef,
    name: "MyAccount___it-it",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___it-it"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___it-it"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___it-it"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___it-it"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___it-it"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___it-it"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___it-it"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___it-it"
    }]
  }, {
    path: "/it-it/newsletter",
    component: _a85becca,
    name: "newsletter___it-it"
  }, {
    path: "/it-it/Newsletter",
    component: _a85becca,
    name: "Newsletter___it-it"
  }, {
    path: "/it-it/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___it-it"
  }, {
    path: "/it-it/Page",
    component: _08b2ebf1,
    name: "Page___it-it"
  }, {
    path: "/it-it/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___it-it"
  }, {
    path: "/it-it/Product",
    component: _4bba9d7d,
    name: "Product___it-it"
  }, {
    path: "/it-it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___it-it"
  }, {
    path: "/it-it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___it-it"
  }, {
    path: "/it-it/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___it-it"
  }, {
    path: "/it-it/Route",
    component: _34cba952,
    name: "Route___it-it"
  }, {
    path: "/it-it/Search",
    component: _6f8b500a,
    name: "Search___it-it"
  }, {
    path: "/it-it/search-result",
    component: _6f8b500a,
    name: "search-result___it-it"
  }, {
    path: "/it-it/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___it-it"
  }, {
    path: "/it-it/work-with-us",
    component: _5dc06952,
    name: "work-with-us___it-it"
  }, {
    path: "/it-it/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___it-it"
  }, {
    path: "/jp-en/boutiques",
    component: _34cef09d,
    name: "boutiques___jp-en",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___jp-en"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___jp-en"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___jp-en"
    }]
  }, {
    path: "/jp-en/Boutiques",
    component: _34cef09d,
    name: "Boutiques___jp-en"
  }, {
    path: "/jp-en/Cart",
    component: _544757fc,
    name: "Cart___jp-en"
  }, {
    path: "/jp-en/Category",
    component: _0b2841e0,
    name: "Category___jp-en"
  }, {
    path: "/jp-en/checkout",
    component: _48ef6e28,
    name: "checkout___jp-en",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___jp-en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___jp-en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___jp-en"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___jp-en"
    }]
  }, {
    path: "/jp-en/Checkout",
    component: _48ef6e28,
    name: "Checkout___jp-en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___jp-en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___jp-en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___jp-en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___jp-en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___jp-en"
    }]
  }, {
    path: "/jp-en/Contact",
    component: _b1da17a4,
    name: "Contact___jp-en"
  }, {
    path: "/jp-en/contacts",
    component: _b1da17a4,
    name: "contacts___jp-en"
  }, {
    path: "/jp-en/contatti",
    component: _b1da17a4,
    name: "contatti___jp-en"
  }, {
    path: "/jp-en/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___jp-en"
  }, {
    path: "/jp-en/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___jp-en"
  }, {
    path: "/jp-en/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___jp-en"
  }, {
    path: "/jp-en/Journal",
    component: _b971c876,
    name: "Journal___jp-en"
  }, {
    path: "/jp-en/JournalView",
    component: _bf42fcec,
    name: "JournalView___jp-en"
  }, {
    path: "/jp-en/MyAccount",
    component: _10189cef,
    name: "MyAccount___jp-en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___jp-en"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___jp-en"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___jp-en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___jp-en"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___jp-en"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___jp-en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___jp-en"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___jp-en"
    }]
  }, {
    path: "/jp-en/newsletter",
    component: _a85becca,
    name: "newsletter___jp-en"
  }, {
    path: "/jp-en/Newsletter",
    component: _a85becca,
    name: "Newsletter___jp-en"
  }, {
    path: "/jp-en/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___jp-en"
  }, {
    path: "/jp-en/Page",
    component: _08b2ebf1,
    name: "Page___jp-en"
  }, {
    path: "/jp-en/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___jp-en"
  }, {
    path: "/jp-en/Product",
    component: _4bba9d7d,
    name: "Product___jp-en"
  }, {
    path: "/jp-en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___jp-en"
  }, {
    path: "/jp-en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___jp-en"
  }, {
    path: "/jp-en/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___jp-en"
  }, {
    path: "/jp-en/Route",
    component: _34cba952,
    name: "Route___jp-en"
  }, {
    path: "/jp-en/Search",
    component: _6f8b500a,
    name: "Search___jp-en"
  }, {
    path: "/jp-en/search-result",
    component: _6f8b500a,
    name: "search-result___jp-en"
  }, {
    path: "/jp-en/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___jp-en"
  }, {
    path: "/jp-en/work-with-us",
    component: _5dc06952,
    name: "work-with-us___jp-en"
  }, {
    path: "/jp-en/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___jp-en"
  }, {
    path: "/jp-it/boutiques",
    component: _34cef09d,
    name: "boutiques___jp-it",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___jp-it"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___jp-it"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___jp-it"
    }]
  }, {
    path: "/jp-it/Boutiques",
    component: _34cef09d,
    name: "Boutiques___jp-it"
  }, {
    path: "/jp-it/Cart",
    component: _544757fc,
    name: "Cart___jp-it"
  }, {
    path: "/jp-it/Category",
    component: _0b2841e0,
    name: "Category___jp-it"
  }, {
    path: "/jp-it/checkout",
    component: _48ef6e28,
    name: "checkout___jp-it",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___jp-it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___jp-it"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___jp-it"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___jp-it"
    }]
  }, {
    path: "/jp-it/Checkout",
    component: _48ef6e28,
    name: "Checkout___jp-it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___jp-it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___jp-it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___jp-it"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___jp-it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___jp-it"
    }]
  }, {
    path: "/jp-it/Contact",
    component: _b1da17a4,
    name: "Contact___jp-it"
  }, {
    path: "/jp-it/contacts",
    component: _b1da17a4,
    name: "contacts___jp-it"
  }, {
    path: "/jp-it/contatti",
    component: _b1da17a4,
    name: "contatti___jp-it"
  }, {
    path: "/jp-it/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___jp-it"
  }, {
    path: "/jp-it/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___jp-it"
  }, {
    path: "/jp-it/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___jp-it"
  }, {
    path: "/jp-it/Journal",
    component: _b971c876,
    name: "Journal___jp-it"
  }, {
    path: "/jp-it/JournalView",
    component: _bf42fcec,
    name: "JournalView___jp-it"
  }, {
    path: "/jp-it/MyAccount",
    component: _10189cef,
    name: "MyAccount___jp-it",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___jp-it"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___jp-it"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___jp-it"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___jp-it"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___jp-it"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___jp-it"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___jp-it"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___jp-it"
    }]
  }, {
    path: "/jp-it/newsletter",
    component: _a85becca,
    name: "newsletter___jp-it"
  }, {
    path: "/jp-it/Newsletter",
    component: _a85becca,
    name: "Newsletter___jp-it"
  }, {
    path: "/jp-it/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___jp-it"
  }, {
    path: "/jp-it/Page",
    component: _08b2ebf1,
    name: "Page___jp-it"
  }, {
    path: "/jp-it/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___jp-it"
  }, {
    path: "/jp-it/Product",
    component: _4bba9d7d,
    name: "Product___jp-it"
  }, {
    path: "/jp-it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___jp-it"
  }, {
    path: "/jp-it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___jp-it"
  }, {
    path: "/jp-it/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___jp-it"
  }, {
    path: "/jp-it/Route",
    component: _34cba952,
    name: "Route___jp-it"
  }, {
    path: "/jp-it/Search",
    component: _6f8b500a,
    name: "Search___jp-it"
  }, {
    path: "/jp-it/search-result",
    component: _6f8b500a,
    name: "search-result___jp-it"
  }, {
    path: "/jp-it/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___jp-it"
  }, {
    path: "/jp-it/work-with-us",
    component: _5dc06952,
    name: "work-with-us___jp-it"
  }, {
    path: "/jp-it/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___jp-it"
  }, {
    path: "/kr-en/boutiques",
    component: _34cef09d,
    name: "boutiques___kr-en",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___kr-en"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___kr-en"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___kr-en"
    }]
  }, {
    path: "/kr-en/Boutiques",
    component: _34cef09d,
    name: "Boutiques___kr-en"
  }, {
    path: "/kr-en/Cart",
    component: _544757fc,
    name: "Cart___kr-en"
  }, {
    path: "/kr-en/Category",
    component: _0b2841e0,
    name: "Category___kr-en"
  }, {
    path: "/kr-en/checkout",
    component: _48ef6e28,
    name: "checkout___kr-en",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___kr-en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___kr-en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___kr-en"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___kr-en"
    }]
  }, {
    path: "/kr-en/Checkout",
    component: _48ef6e28,
    name: "Checkout___kr-en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___kr-en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___kr-en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___kr-en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___kr-en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___kr-en"
    }]
  }, {
    path: "/kr-en/Contact",
    component: _b1da17a4,
    name: "Contact___kr-en"
  }, {
    path: "/kr-en/contacts",
    component: _b1da17a4,
    name: "contacts___kr-en"
  }, {
    path: "/kr-en/contatti",
    component: _b1da17a4,
    name: "contatti___kr-en"
  }, {
    path: "/kr-en/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___kr-en"
  }, {
    path: "/kr-en/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___kr-en"
  }, {
    path: "/kr-en/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___kr-en"
  }, {
    path: "/kr-en/Journal",
    component: _b971c876,
    name: "Journal___kr-en"
  }, {
    path: "/kr-en/JournalView",
    component: _bf42fcec,
    name: "JournalView___kr-en"
  }, {
    path: "/kr-en/MyAccount",
    component: _10189cef,
    name: "MyAccount___kr-en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___kr-en"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___kr-en"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___kr-en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___kr-en"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___kr-en"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___kr-en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___kr-en"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___kr-en"
    }]
  }, {
    path: "/kr-en/newsletter",
    component: _a85becca,
    name: "newsletter___kr-en"
  }, {
    path: "/kr-en/Newsletter",
    component: _a85becca,
    name: "Newsletter___kr-en"
  }, {
    path: "/kr-en/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___kr-en"
  }, {
    path: "/kr-en/Page",
    component: _08b2ebf1,
    name: "Page___kr-en"
  }, {
    path: "/kr-en/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___kr-en"
  }, {
    path: "/kr-en/Product",
    component: _4bba9d7d,
    name: "Product___kr-en"
  }, {
    path: "/kr-en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___kr-en"
  }, {
    path: "/kr-en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___kr-en"
  }, {
    path: "/kr-en/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___kr-en"
  }, {
    path: "/kr-en/Route",
    component: _34cba952,
    name: "Route___kr-en"
  }, {
    path: "/kr-en/Search",
    component: _6f8b500a,
    name: "Search___kr-en"
  }, {
    path: "/kr-en/search-result",
    component: _6f8b500a,
    name: "search-result___kr-en"
  }, {
    path: "/kr-en/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___kr-en"
  }, {
    path: "/kr-en/work-with-us",
    component: _5dc06952,
    name: "work-with-us___kr-en"
  }, {
    path: "/kr-en/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___kr-en"
  }, {
    path: "/kr-it/boutiques",
    component: _34cef09d,
    name: "boutiques___kr-it",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___kr-it"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___kr-it"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___kr-it"
    }]
  }, {
    path: "/kr-it/Boutiques",
    component: _34cef09d,
    name: "Boutiques___kr-it"
  }, {
    path: "/kr-it/Cart",
    component: _544757fc,
    name: "Cart___kr-it"
  }, {
    path: "/kr-it/Category",
    component: _0b2841e0,
    name: "Category___kr-it"
  }, {
    path: "/kr-it/checkout",
    component: _48ef6e28,
    name: "checkout___kr-it",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___kr-it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___kr-it"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___kr-it"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___kr-it"
    }]
  }, {
    path: "/kr-it/Checkout",
    component: _48ef6e28,
    name: "Checkout___kr-it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___kr-it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___kr-it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___kr-it"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___kr-it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___kr-it"
    }]
  }, {
    path: "/kr-it/Contact",
    component: _b1da17a4,
    name: "Contact___kr-it"
  }, {
    path: "/kr-it/contacts",
    component: _b1da17a4,
    name: "contacts___kr-it"
  }, {
    path: "/kr-it/contatti",
    component: _b1da17a4,
    name: "contatti___kr-it"
  }, {
    path: "/kr-it/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___kr-it"
  }, {
    path: "/kr-it/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___kr-it"
  }, {
    path: "/kr-it/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___kr-it"
  }, {
    path: "/kr-it/Journal",
    component: _b971c876,
    name: "Journal___kr-it"
  }, {
    path: "/kr-it/JournalView",
    component: _bf42fcec,
    name: "JournalView___kr-it"
  }, {
    path: "/kr-it/MyAccount",
    component: _10189cef,
    name: "MyAccount___kr-it",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___kr-it"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___kr-it"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___kr-it"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___kr-it"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___kr-it"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___kr-it"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___kr-it"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___kr-it"
    }]
  }, {
    path: "/kr-it/newsletter",
    component: _a85becca,
    name: "newsletter___kr-it"
  }, {
    path: "/kr-it/Newsletter",
    component: _a85becca,
    name: "Newsletter___kr-it"
  }, {
    path: "/kr-it/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___kr-it"
  }, {
    path: "/kr-it/Page",
    component: _08b2ebf1,
    name: "Page___kr-it"
  }, {
    path: "/kr-it/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___kr-it"
  }, {
    path: "/kr-it/Product",
    component: _4bba9d7d,
    name: "Product___kr-it"
  }, {
    path: "/kr-it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___kr-it"
  }, {
    path: "/kr-it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___kr-it"
  }, {
    path: "/kr-it/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___kr-it"
  }, {
    path: "/kr-it/Route",
    component: _34cba952,
    name: "Route___kr-it"
  }, {
    path: "/kr-it/Search",
    component: _6f8b500a,
    name: "Search___kr-it"
  }, {
    path: "/kr-it/search-result",
    component: _6f8b500a,
    name: "search-result___kr-it"
  }, {
    path: "/kr-it/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___kr-it"
  }, {
    path: "/kr-it/work-with-us",
    component: _5dc06952,
    name: "work-with-us___kr-it"
  }, {
    path: "/kr-it/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___kr-it"
  }, {
    path: "/kw-en/boutiques",
    component: _34cef09d,
    name: "boutiques___kw-en",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___kw-en"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___kw-en"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___kw-en"
    }]
  }, {
    path: "/kw-en/Boutiques",
    component: _34cef09d,
    name: "Boutiques___kw-en"
  }, {
    path: "/kw-en/Cart",
    component: _544757fc,
    name: "Cart___kw-en"
  }, {
    path: "/kw-en/Category",
    component: _0b2841e0,
    name: "Category___kw-en"
  }, {
    path: "/kw-en/checkout",
    component: _48ef6e28,
    name: "checkout___kw-en",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___kw-en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___kw-en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___kw-en"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___kw-en"
    }]
  }, {
    path: "/kw-en/Checkout",
    component: _48ef6e28,
    name: "Checkout___kw-en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___kw-en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___kw-en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___kw-en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___kw-en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___kw-en"
    }]
  }, {
    path: "/kw-en/Contact",
    component: _b1da17a4,
    name: "Contact___kw-en"
  }, {
    path: "/kw-en/contacts",
    component: _b1da17a4,
    name: "contacts___kw-en"
  }, {
    path: "/kw-en/contatti",
    component: _b1da17a4,
    name: "contatti___kw-en"
  }, {
    path: "/kw-en/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___kw-en"
  }, {
    path: "/kw-en/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___kw-en"
  }, {
    path: "/kw-en/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___kw-en"
  }, {
    path: "/kw-en/Journal",
    component: _b971c876,
    name: "Journal___kw-en"
  }, {
    path: "/kw-en/JournalView",
    component: _bf42fcec,
    name: "JournalView___kw-en"
  }, {
    path: "/kw-en/MyAccount",
    component: _10189cef,
    name: "MyAccount___kw-en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___kw-en"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___kw-en"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___kw-en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___kw-en"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___kw-en"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___kw-en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___kw-en"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___kw-en"
    }]
  }, {
    path: "/kw-en/newsletter",
    component: _a85becca,
    name: "newsletter___kw-en"
  }, {
    path: "/kw-en/Newsletter",
    component: _a85becca,
    name: "Newsletter___kw-en"
  }, {
    path: "/kw-en/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___kw-en"
  }, {
    path: "/kw-en/Page",
    component: _08b2ebf1,
    name: "Page___kw-en"
  }, {
    path: "/kw-en/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___kw-en"
  }, {
    path: "/kw-en/Product",
    component: _4bba9d7d,
    name: "Product___kw-en"
  }, {
    path: "/kw-en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___kw-en"
  }, {
    path: "/kw-en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___kw-en"
  }, {
    path: "/kw-en/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___kw-en"
  }, {
    path: "/kw-en/Route",
    component: _34cba952,
    name: "Route___kw-en"
  }, {
    path: "/kw-en/Search",
    component: _6f8b500a,
    name: "Search___kw-en"
  }, {
    path: "/kw-en/search-result",
    component: _6f8b500a,
    name: "search-result___kw-en"
  }, {
    path: "/kw-en/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___kw-en"
  }, {
    path: "/kw-en/work-with-us",
    component: _5dc06952,
    name: "work-with-us___kw-en"
  }, {
    path: "/kw-en/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___kw-en"
  }, {
    path: "/kw-it/boutiques",
    component: _34cef09d,
    name: "boutiques___kw-it",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___kw-it"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___kw-it"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___kw-it"
    }]
  }, {
    path: "/kw-it/Boutiques",
    component: _34cef09d,
    name: "Boutiques___kw-it"
  }, {
    path: "/kw-it/Cart",
    component: _544757fc,
    name: "Cart___kw-it"
  }, {
    path: "/kw-it/Category",
    component: _0b2841e0,
    name: "Category___kw-it"
  }, {
    path: "/kw-it/checkout",
    component: _48ef6e28,
    name: "checkout___kw-it",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___kw-it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___kw-it"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___kw-it"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___kw-it"
    }]
  }, {
    path: "/kw-it/Checkout",
    component: _48ef6e28,
    name: "Checkout___kw-it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___kw-it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___kw-it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___kw-it"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___kw-it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___kw-it"
    }]
  }, {
    path: "/kw-it/Contact",
    component: _b1da17a4,
    name: "Contact___kw-it"
  }, {
    path: "/kw-it/contacts",
    component: _b1da17a4,
    name: "contacts___kw-it"
  }, {
    path: "/kw-it/contatti",
    component: _b1da17a4,
    name: "contatti___kw-it"
  }, {
    path: "/kw-it/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___kw-it"
  }, {
    path: "/kw-it/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___kw-it"
  }, {
    path: "/kw-it/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___kw-it"
  }, {
    path: "/kw-it/Journal",
    component: _b971c876,
    name: "Journal___kw-it"
  }, {
    path: "/kw-it/JournalView",
    component: _bf42fcec,
    name: "JournalView___kw-it"
  }, {
    path: "/kw-it/MyAccount",
    component: _10189cef,
    name: "MyAccount___kw-it",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___kw-it"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___kw-it"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___kw-it"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___kw-it"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___kw-it"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___kw-it"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___kw-it"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___kw-it"
    }]
  }, {
    path: "/kw-it/newsletter",
    component: _a85becca,
    name: "newsletter___kw-it"
  }, {
    path: "/kw-it/Newsletter",
    component: _a85becca,
    name: "Newsletter___kw-it"
  }, {
    path: "/kw-it/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___kw-it"
  }, {
    path: "/kw-it/Page",
    component: _08b2ebf1,
    name: "Page___kw-it"
  }, {
    path: "/kw-it/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___kw-it"
  }, {
    path: "/kw-it/Product",
    component: _4bba9d7d,
    name: "Product___kw-it"
  }, {
    path: "/kw-it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___kw-it"
  }, {
    path: "/kw-it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___kw-it"
  }, {
    path: "/kw-it/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___kw-it"
  }, {
    path: "/kw-it/Route",
    component: _34cba952,
    name: "Route___kw-it"
  }, {
    path: "/kw-it/Search",
    component: _6f8b500a,
    name: "Search___kw-it"
  }, {
    path: "/kw-it/search-result",
    component: _6f8b500a,
    name: "search-result___kw-it"
  }, {
    path: "/kw-it/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___kw-it"
  }, {
    path: "/kw-it/work-with-us",
    component: _5dc06952,
    name: "work-with-us___kw-it"
  }, {
    path: "/kw-it/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___kw-it"
  }, {
    path: "/mo-en/boutiques",
    component: _34cef09d,
    name: "boutiques___mo-en",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___mo-en"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___mo-en"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___mo-en"
    }]
  }, {
    path: "/mo-en/Boutiques",
    component: _34cef09d,
    name: "Boutiques___mo-en"
  }, {
    path: "/mo-en/Cart",
    component: _544757fc,
    name: "Cart___mo-en"
  }, {
    path: "/mo-en/Category",
    component: _0b2841e0,
    name: "Category___mo-en"
  }, {
    path: "/mo-en/checkout",
    component: _48ef6e28,
    name: "checkout___mo-en",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___mo-en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___mo-en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___mo-en"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___mo-en"
    }]
  }, {
    path: "/mo-en/Checkout",
    component: _48ef6e28,
    name: "Checkout___mo-en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___mo-en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___mo-en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___mo-en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___mo-en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___mo-en"
    }]
  }, {
    path: "/mo-en/Contact",
    component: _b1da17a4,
    name: "Contact___mo-en"
  }, {
    path: "/mo-en/contacts",
    component: _b1da17a4,
    name: "contacts___mo-en"
  }, {
    path: "/mo-en/contatti",
    component: _b1da17a4,
    name: "contatti___mo-en"
  }, {
    path: "/mo-en/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___mo-en"
  }, {
    path: "/mo-en/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___mo-en"
  }, {
    path: "/mo-en/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___mo-en"
  }, {
    path: "/mo-en/Journal",
    component: _b971c876,
    name: "Journal___mo-en"
  }, {
    path: "/mo-en/JournalView",
    component: _bf42fcec,
    name: "JournalView___mo-en"
  }, {
    path: "/mo-en/MyAccount",
    component: _10189cef,
    name: "MyAccount___mo-en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___mo-en"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___mo-en"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___mo-en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___mo-en"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___mo-en"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___mo-en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___mo-en"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___mo-en"
    }]
  }, {
    path: "/mo-en/newsletter",
    component: _a85becca,
    name: "newsletter___mo-en"
  }, {
    path: "/mo-en/Newsletter",
    component: _a85becca,
    name: "Newsletter___mo-en"
  }, {
    path: "/mo-en/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___mo-en"
  }, {
    path: "/mo-en/Page",
    component: _08b2ebf1,
    name: "Page___mo-en"
  }, {
    path: "/mo-en/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___mo-en"
  }, {
    path: "/mo-en/Product",
    component: _4bba9d7d,
    name: "Product___mo-en"
  }, {
    path: "/mo-en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___mo-en"
  }, {
    path: "/mo-en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___mo-en"
  }, {
    path: "/mo-en/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___mo-en"
  }, {
    path: "/mo-en/Route",
    component: _34cba952,
    name: "Route___mo-en"
  }, {
    path: "/mo-en/Search",
    component: _6f8b500a,
    name: "Search___mo-en"
  }, {
    path: "/mo-en/search-result",
    component: _6f8b500a,
    name: "search-result___mo-en"
  }, {
    path: "/mo-en/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___mo-en"
  }, {
    path: "/mo-en/work-with-us",
    component: _5dc06952,
    name: "work-with-us___mo-en"
  }, {
    path: "/mo-en/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___mo-en"
  }, {
    path: "/mo-it/boutiques",
    component: _34cef09d,
    name: "boutiques___mo-it",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___mo-it"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___mo-it"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___mo-it"
    }]
  }, {
    path: "/mo-it/Boutiques",
    component: _34cef09d,
    name: "Boutiques___mo-it"
  }, {
    path: "/mo-it/Cart",
    component: _544757fc,
    name: "Cart___mo-it"
  }, {
    path: "/mo-it/Category",
    component: _0b2841e0,
    name: "Category___mo-it"
  }, {
    path: "/mo-it/checkout",
    component: _48ef6e28,
    name: "checkout___mo-it",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___mo-it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___mo-it"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___mo-it"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___mo-it"
    }]
  }, {
    path: "/mo-it/Checkout",
    component: _48ef6e28,
    name: "Checkout___mo-it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___mo-it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___mo-it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___mo-it"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___mo-it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___mo-it"
    }]
  }, {
    path: "/mo-it/Contact",
    component: _b1da17a4,
    name: "Contact___mo-it"
  }, {
    path: "/mo-it/contacts",
    component: _b1da17a4,
    name: "contacts___mo-it"
  }, {
    path: "/mo-it/contatti",
    component: _b1da17a4,
    name: "contatti___mo-it"
  }, {
    path: "/mo-it/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___mo-it"
  }, {
    path: "/mo-it/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___mo-it"
  }, {
    path: "/mo-it/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___mo-it"
  }, {
    path: "/mo-it/Journal",
    component: _b971c876,
    name: "Journal___mo-it"
  }, {
    path: "/mo-it/JournalView",
    component: _bf42fcec,
    name: "JournalView___mo-it"
  }, {
    path: "/mo-it/MyAccount",
    component: _10189cef,
    name: "MyAccount___mo-it",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___mo-it"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___mo-it"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___mo-it"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___mo-it"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___mo-it"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___mo-it"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___mo-it"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___mo-it"
    }]
  }, {
    path: "/mo-it/newsletter",
    component: _a85becca,
    name: "newsletter___mo-it"
  }, {
    path: "/mo-it/Newsletter",
    component: _a85becca,
    name: "Newsletter___mo-it"
  }, {
    path: "/mo-it/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___mo-it"
  }, {
    path: "/mo-it/Page",
    component: _08b2ebf1,
    name: "Page___mo-it"
  }, {
    path: "/mo-it/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___mo-it"
  }, {
    path: "/mo-it/Product",
    component: _4bba9d7d,
    name: "Product___mo-it"
  }, {
    path: "/mo-it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___mo-it"
  }, {
    path: "/mo-it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___mo-it"
  }, {
    path: "/mo-it/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___mo-it"
  }, {
    path: "/mo-it/Route",
    component: _34cba952,
    name: "Route___mo-it"
  }, {
    path: "/mo-it/Search",
    component: _6f8b500a,
    name: "Search___mo-it"
  }, {
    path: "/mo-it/search-result",
    component: _6f8b500a,
    name: "search-result___mo-it"
  }, {
    path: "/mo-it/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___mo-it"
  }, {
    path: "/mo-it/work-with-us",
    component: _5dc06952,
    name: "work-with-us___mo-it"
  }, {
    path: "/mo-it/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___mo-it"
  }, {
    path: "/qa-en/boutiques",
    component: _34cef09d,
    name: "boutiques___qa-en",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___qa-en"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___qa-en"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___qa-en"
    }]
  }, {
    path: "/qa-en/Boutiques",
    component: _34cef09d,
    name: "Boutiques___qa-en"
  }, {
    path: "/qa-en/Cart",
    component: _544757fc,
    name: "Cart___qa-en"
  }, {
    path: "/qa-en/Category",
    component: _0b2841e0,
    name: "Category___qa-en"
  }, {
    path: "/qa-en/checkout",
    component: _48ef6e28,
    name: "checkout___qa-en",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___qa-en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___qa-en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___qa-en"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___qa-en"
    }]
  }, {
    path: "/qa-en/Checkout",
    component: _48ef6e28,
    name: "Checkout___qa-en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___qa-en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___qa-en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___qa-en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___qa-en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___qa-en"
    }]
  }, {
    path: "/qa-en/Contact",
    component: _b1da17a4,
    name: "Contact___qa-en"
  }, {
    path: "/qa-en/contacts",
    component: _b1da17a4,
    name: "contacts___qa-en"
  }, {
    path: "/qa-en/contatti",
    component: _b1da17a4,
    name: "contatti___qa-en"
  }, {
    path: "/qa-en/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___qa-en"
  }, {
    path: "/qa-en/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___qa-en"
  }, {
    path: "/qa-en/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___qa-en"
  }, {
    path: "/qa-en/Journal",
    component: _b971c876,
    name: "Journal___qa-en"
  }, {
    path: "/qa-en/JournalView",
    component: _bf42fcec,
    name: "JournalView___qa-en"
  }, {
    path: "/qa-en/MyAccount",
    component: _10189cef,
    name: "MyAccount___qa-en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___qa-en"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___qa-en"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___qa-en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___qa-en"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___qa-en"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___qa-en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___qa-en"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___qa-en"
    }]
  }, {
    path: "/qa-en/newsletter",
    component: _a85becca,
    name: "newsletter___qa-en"
  }, {
    path: "/qa-en/Newsletter",
    component: _a85becca,
    name: "Newsletter___qa-en"
  }, {
    path: "/qa-en/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___qa-en"
  }, {
    path: "/qa-en/Page",
    component: _08b2ebf1,
    name: "Page___qa-en"
  }, {
    path: "/qa-en/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___qa-en"
  }, {
    path: "/qa-en/Product",
    component: _4bba9d7d,
    name: "Product___qa-en"
  }, {
    path: "/qa-en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___qa-en"
  }, {
    path: "/qa-en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___qa-en"
  }, {
    path: "/qa-en/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___qa-en"
  }, {
    path: "/qa-en/Route",
    component: _34cba952,
    name: "Route___qa-en"
  }, {
    path: "/qa-en/Search",
    component: _6f8b500a,
    name: "Search___qa-en"
  }, {
    path: "/qa-en/search-result",
    component: _6f8b500a,
    name: "search-result___qa-en"
  }, {
    path: "/qa-en/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___qa-en"
  }, {
    path: "/qa-en/work-with-us",
    component: _5dc06952,
    name: "work-with-us___qa-en"
  }, {
    path: "/qa-en/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___qa-en"
  }, {
    path: "/qa-it/boutiques",
    component: _34cef09d,
    name: "boutiques___qa-it",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___qa-it"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___qa-it"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___qa-it"
    }]
  }, {
    path: "/qa-it/Boutiques",
    component: _34cef09d,
    name: "Boutiques___qa-it"
  }, {
    path: "/qa-it/Cart",
    component: _544757fc,
    name: "Cart___qa-it"
  }, {
    path: "/qa-it/Category",
    component: _0b2841e0,
    name: "Category___qa-it"
  }, {
    path: "/qa-it/checkout",
    component: _48ef6e28,
    name: "checkout___qa-it",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___qa-it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___qa-it"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___qa-it"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___qa-it"
    }]
  }, {
    path: "/qa-it/Checkout",
    component: _48ef6e28,
    name: "Checkout___qa-it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___qa-it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___qa-it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___qa-it"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___qa-it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___qa-it"
    }]
  }, {
    path: "/qa-it/Contact",
    component: _b1da17a4,
    name: "Contact___qa-it"
  }, {
    path: "/qa-it/contacts",
    component: _b1da17a4,
    name: "contacts___qa-it"
  }, {
    path: "/qa-it/contatti",
    component: _b1da17a4,
    name: "contatti___qa-it"
  }, {
    path: "/qa-it/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___qa-it"
  }, {
    path: "/qa-it/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___qa-it"
  }, {
    path: "/qa-it/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___qa-it"
  }, {
    path: "/qa-it/Journal",
    component: _b971c876,
    name: "Journal___qa-it"
  }, {
    path: "/qa-it/JournalView",
    component: _bf42fcec,
    name: "JournalView___qa-it"
  }, {
    path: "/qa-it/MyAccount",
    component: _10189cef,
    name: "MyAccount___qa-it",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___qa-it"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___qa-it"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___qa-it"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___qa-it"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___qa-it"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___qa-it"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___qa-it"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___qa-it"
    }]
  }, {
    path: "/qa-it/newsletter",
    component: _a85becca,
    name: "newsletter___qa-it"
  }, {
    path: "/qa-it/Newsletter",
    component: _a85becca,
    name: "Newsletter___qa-it"
  }, {
    path: "/qa-it/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___qa-it"
  }, {
    path: "/qa-it/Page",
    component: _08b2ebf1,
    name: "Page___qa-it"
  }, {
    path: "/qa-it/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___qa-it"
  }, {
    path: "/qa-it/Product",
    component: _4bba9d7d,
    name: "Product___qa-it"
  }, {
    path: "/qa-it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___qa-it"
  }, {
    path: "/qa-it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___qa-it"
  }, {
    path: "/qa-it/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___qa-it"
  }, {
    path: "/qa-it/Route",
    component: _34cba952,
    name: "Route___qa-it"
  }, {
    path: "/qa-it/Search",
    component: _6f8b500a,
    name: "Search___qa-it"
  }, {
    path: "/qa-it/search-result",
    component: _6f8b500a,
    name: "search-result___qa-it"
  }, {
    path: "/qa-it/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___qa-it"
  }, {
    path: "/qa-it/work-with-us",
    component: _5dc06952,
    name: "work-with-us___qa-it"
  }, {
    path: "/qa-it/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___qa-it"
  }, {
    path: "/row-en/boutiques",
    component: _34cef09d,
    name: "boutiques___row-en",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___row-en"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___row-en"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___row-en"
    }]
  }, {
    path: "/row-en/Boutiques",
    component: _34cef09d,
    name: "Boutiques___row-en"
  }, {
    path: "/row-en/Cart",
    component: _544757fc,
    name: "Cart___row-en"
  }, {
    path: "/row-en/Category",
    component: _0b2841e0,
    name: "Category___row-en"
  }, {
    path: "/row-en/checkout",
    component: _48ef6e28,
    name: "checkout___row-en",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___row-en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___row-en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___row-en"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___row-en"
    }]
  }, {
    path: "/row-en/Checkout",
    component: _48ef6e28,
    name: "Checkout___row-en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___row-en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___row-en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___row-en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___row-en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___row-en"
    }]
  }, {
    path: "/row-en/Contact",
    component: _b1da17a4,
    name: "Contact___row-en"
  }, {
    path: "/row-en/contacts",
    component: _b1da17a4,
    name: "contacts___row-en"
  }, {
    path: "/row-en/contatti",
    component: _b1da17a4,
    name: "contatti___row-en"
  }, {
    path: "/row-en/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___row-en"
  }, {
    path: "/row-en/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___row-en"
  }, {
    path: "/row-en/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___row-en"
  }, {
    path: "/row-en/Journal",
    component: _b971c876,
    name: "Journal___row-en"
  }, {
    path: "/row-en/JournalView",
    component: _bf42fcec,
    name: "JournalView___row-en"
  }, {
    path: "/row-en/MyAccount",
    component: _10189cef,
    name: "MyAccount___row-en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___row-en"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___row-en"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___row-en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___row-en"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___row-en"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___row-en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___row-en"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___row-en"
    }]
  }, {
    path: "/row-en/newsletter",
    component: _a85becca,
    name: "newsletter___row-en"
  }, {
    path: "/row-en/Newsletter",
    component: _a85becca,
    name: "Newsletter___row-en"
  }, {
    path: "/row-en/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___row-en"
  }, {
    path: "/row-en/Page",
    component: _08b2ebf1,
    name: "Page___row-en"
  }, {
    path: "/row-en/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___row-en"
  }, {
    path: "/row-en/Product",
    component: _4bba9d7d,
    name: "Product___row-en"
  }, {
    path: "/row-en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___row-en"
  }, {
    path: "/row-en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___row-en"
  }, {
    path: "/row-en/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___row-en"
  }, {
    path: "/row-en/Route",
    component: _34cba952,
    name: "Route___row-en"
  }, {
    path: "/row-en/Search",
    component: _6f8b500a,
    name: "Search___row-en"
  }, {
    path: "/row-en/search-result",
    component: _6f8b500a,
    name: "search-result___row-en"
  }, {
    path: "/row-en/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___row-en"
  }, {
    path: "/row-en/work-with-us",
    component: _5dc06952,
    name: "work-with-us___row-en"
  }, {
    path: "/row-en/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___row-en"
  }, {
    path: "/row-it/boutiques",
    component: _34cef09d,
    name: "boutiques___row-it",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___row-it"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___row-it"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___row-it"
    }]
  }, {
    path: "/row-it/Boutiques",
    component: _34cef09d,
    name: "Boutiques___row-it"
  }, {
    path: "/row-it/Cart",
    component: _544757fc,
    name: "Cart___row-it"
  }, {
    path: "/row-it/Category",
    component: _0b2841e0,
    name: "Category___row-it"
  }, {
    path: "/row-it/checkout",
    component: _48ef6e28,
    name: "checkout___row-it",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___row-it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___row-it"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___row-it"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___row-it"
    }]
  }, {
    path: "/row-it/Checkout",
    component: _48ef6e28,
    name: "Checkout___row-it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___row-it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___row-it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___row-it"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___row-it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___row-it"
    }]
  }, {
    path: "/row-it/Contact",
    component: _b1da17a4,
    name: "Contact___row-it"
  }, {
    path: "/row-it/contacts",
    component: _b1da17a4,
    name: "contacts___row-it"
  }, {
    path: "/row-it/contatti",
    component: _b1da17a4,
    name: "contatti___row-it"
  }, {
    path: "/row-it/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___row-it"
  }, {
    path: "/row-it/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___row-it"
  }, {
    path: "/row-it/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___row-it"
  }, {
    path: "/row-it/Journal",
    component: _b971c876,
    name: "Journal___row-it"
  }, {
    path: "/row-it/JournalView",
    component: _bf42fcec,
    name: "JournalView___row-it"
  }, {
    path: "/row-it/MyAccount",
    component: _10189cef,
    name: "MyAccount___row-it",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___row-it"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___row-it"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___row-it"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___row-it"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___row-it"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___row-it"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___row-it"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___row-it"
    }]
  }, {
    path: "/row-it/newsletter",
    component: _a85becca,
    name: "newsletter___row-it"
  }, {
    path: "/row-it/Newsletter",
    component: _a85becca,
    name: "Newsletter___row-it"
  }, {
    path: "/row-it/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___row-it"
  }, {
    path: "/row-it/Page",
    component: _08b2ebf1,
    name: "Page___row-it"
  }, {
    path: "/row-it/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___row-it"
  }, {
    path: "/row-it/Product",
    component: _4bba9d7d,
    name: "Product___row-it"
  }, {
    path: "/row-it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___row-it"
  }, {
    path: "/row-it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___row-it"
  }, {
    path: "/row-it/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___row-it"
  }, {
    path: "/row-it/Route",
    component: _34cba952,
    name: "Route___row-it"
  }, {
    path: "/row-it/Search",
    component: _6f8b500a,
    name: "Search___row-it"
  }, {
    path: "/row-it/search-result",
    component: _6f8b500a,
    name: "search-result___row-it"
  }, {
    path: "/row-it/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___row-it"
  }, {
    path: "/row-it/work-with-us",
    component: _5dc06952,
    name: "work-with-us___row-it"
  }, {
    path: "/row-it/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___row-it"
  }, {
    path: "/sg-en/boutiques",
    component: _34cef09d,
    name: "boutiques___sg-en",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___sg-en"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___sg-en"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___sg-en"
    }]
  }, {
    path: "/sg-en/Boutiques",
    component: _34cef09d,
    name: "Boutiques___sg-en"
  }, {
    path: "/sg-en/Cart",
    component: _544757fc,
    name: "Cart___sg-en"
  }, {
    path: "/sg-en/Category",
    component: _0b2841e0,
    name: "Category___sg-en"
  }, {
    path: "/sg-en/checkout",
    component: _48ef6e28,
    name: "checkout___sg-en",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___sg-en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___sg-en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___sg-en"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___sg-en"
    }]
  }, {
    path: "/sg-en/Checkout",
    component: _48ef6e28,
    name: "Checkout___sg-en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___sg-en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___sg-en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___sg-en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___sg-en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___sg-en"
    }]
  }, {
    path: "/sg-en/Contact",
    component: _b1da17a4,
    name: "Contact___sg-en"
  }, {
    path: "/sg-en/contacts",
    component: _b1da17a4,
    name: "contacts___sg-en"
  }, {
    path: "/sg-en/contatti",
    component: _b1da17a4,
    name: "contatti___sg-en"
  }, {
    path: "/sg-en/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___sg-en"
  }, {
    path: "/sg-en/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___sg-en"
  }, {
    path: "/sg-en/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___sg-en"
  }, {
    path: "/sg-en/Journal",
    component: _b971c876,
    name: "Journal___sg-en"
  }, {
    path: "/sg-en/JournalView",
    component: _bf42fcec,
    name: "JournalView___sg-en"
  }, {
    path: "/sg-en/MyAccount",
    component: _10189cef,
    name: "MyAccount___sg-en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___sg-en"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___sg-en"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___sg-en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___sg-en"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___sg-en"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___sg-en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___sg-en"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___sg-en"
    }]
  }, {
    path: "/sg-en/newsletter",
    component: _a85becca,
    name: "newsletter___sg-en"
  }, {
    path: "/sg-en/Newsletter",
    component: _a85becca,
    name: "Newsletter___sg-en"
  }, {
    path: "/sg-en/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___sg-en"
  }, {
    path: "/sg-en/Page",
    component: _08b2ebf1,
    name: "Page___sg-en"
  }, {
    path: "/sg-en/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___sg-en"
  }, {
    path: "/sg-en/Product",
    component: _4bba9d7d,
    name: "Product___sg-en"
  }, {
    path: "/sg-en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___sg-en"
  }, {
    path: "/sg-en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___sg-en"
  }, {
    path: "/sg-en/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___sg-en"
  }, {
    path: "/sg-en/Route",
    component: _34cba952,
    name: "Route___sg-en"
  }, {
    path: "/sg-en/Search",
    component: _6f8b500a,
    name: "Search___sg-en"
  }, {
    path: "/sg-en/search-result",
    component: _6f8b500a,
    name: "search-result___sg-en"
  }, {
    path: "/sg-en/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___sg-en"
  }, {
    path: "/sg-en/work-with-us",
    component: _5dc06952,
    name: "work-with-us___sg-en"
  }, {
    path: "/sg-en/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___sg-en"
  }, {
    path: "/sg-it/boutiques",
    component: _34cef09d,
    name: "boutiques___sg-it",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___sg-it"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___sg-it"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___sg-it"
    }]
  }, {
    path: "/sg-it/Boutiques",
    component: _34cef09d,
    name: "Boutiques___sg-it"
  }, {
    path: "/sg-it/Cart",
    component: _544757fc,
    name: "Cart___sg-it"
  }, {
    path: "/sg-it/Category",
    component: _0b2841e0,
    name: "Category___sg-it"
  }, {
    path: "/sg-it/checkout",
    component: _48ef6e28,
    name: "checkout___sg-it",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___sg-it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___sg-it"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___sg-it"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___sg-it"
    }]
  }, {
    path: "/sg-it/Checkout",
    component: _48ef6e28,
    name: "Checkout___sg-it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___sg-it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___sg-it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___sg-it"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___sg-it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___sg-it"
    }]
  }, {
    path: "/sg-it/Contact",
    component: _b1da17a4,
    name: "Contact___sg-it"
  }, {
    path: "/sg-it/contacts",
    component: _b1da17a4,
    name: "contacts___sg-it"
  }, {
    path: "/sg-it/contatti",
    component: _b1da17a4,
    name: "contatti___sg-it"
  }, {
    path: "/sg-it/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___sg-it"
  }, {
    path: "/sg-it/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___sg-it"
  }, {
    path: "/sg-it/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___sg-it"
  }, {
    path: "/sg-it/Journal",
    component: _b971c876,
    name: "Journal___sg-it"
  }, {
    path: "/sg-it/JournalView",
    component: _bf42fcec,
    name: "JournalView___sg-it"
  }, {
    path: "/sg-it/MyAccount",
    component: _10189cef,
    name: "MyAccount___sg-it",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___sg-it"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___sg-it"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___sg-it"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___sg-it"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___sg-it"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___sg-it"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___sg-it"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___sg-it"
    }]
  }, {
    path: "/sg-it/newsletter",
    component: _a85becca,
    name: "newsletter___sg-it"
  }, {
    path: "/sg-it/Newsletter",
    component: _a85becca,
    name: "Newsletter___sg-it"
  }, {
    path: "/sg-it/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___sg-it"
  }, {
    path: "/sg-it/Page",
    component: _08b2ebf1,
    name: "Page___sg-it"
  }, {
    path: "/sg-it/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___sg-it"
  }, {
    path: "/sg-it/Product",
    component: _4bba9d7d,
    name: "Product___sg-it"
  }, {
    path: "/sg-it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___sg-it"
  }, {
    path: "/sg-it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___sg-it"
  }, {
    path: "/sg-it/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___sg-it"
  }, {
    path: "/sg-it/Route",
    component: _34cba952,
    name: "Route___sg-it"
  }, {
    path: "/sg-it/Search",
    component: _6f8b500a,
    name: "Search___sg-it"
  }, {
    path: "/sg-it/search-result",
    component: _6f8b500a,
    name: "search-result___sg-it"
  }, {
    path: "/sg-it/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___sg-it"
  }, {
    path: "/sg-it/work-with-us",
    component: _5dc06952,
    name: "work-with-us___sg-it"
  }, {
    path: "/sg-it/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___sg-it"
  }, {
    path: "/ue-en/boutiques",
    component: _34cef09d,
    name: "boutiques___ue-en",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___ue-en"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___ue-en"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___ue-en"
    }]
  }, {
    path: "/ue-en/Boutiques",
    component: _34cef09d,
    name: "Boutiques___ue-en"
  }, {
    path: "/ue-en/Cart",
    component: _544757fc,
    name: "Cart___ue-en"
  }, {
    path: "/ue-en/Category",
    component: _0b2841e0,
    name: "Category___ue-en"
  }, {
    path: "/ue-en/checkout",
    component: _48ef6e28,
    name: "checkout___ue-en",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___ue-en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___ue-en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___ue-en"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___ue-en"
    }]
  }, {
    path: "/ue-en/Checkout",
    component: _48ef6e28,
    name: "Checkout___ue-en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___ue-en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___ue-en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___ue-en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___ue-en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___ue-en"
    }]
  }, {
    path: "/ue-en/Contact",
    component: _b1da17a4,
    name: "Contact___ue-en"
  }, {
    path: "/ue-en/contacts",
    component: _b1da17a4,
    name: "contacts___ue-en"
  }, {
    path: "/ue-en/contatti",
    component: _b1da17a4,
    name: "contatti___ue-en"
  }, {
    path: "/ue-en/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___ue-en"
  }, {
    path: "/ue-en/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___ue-en"
  }, {
    path: "/ue-en/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___ue-en"
  }, {
    path: "/ue-en/Journal",
    component: _b971c876,
    name: "Journal___ue-en"
  }, {
    path: "/ue-en/JournalView",
    component: _bf42fcec,
    name: "JournalView___ue-en"
  }, {
    path: "/ue-en/MyAccount",
    component: _10189cef,
    name: "MyAccount___ue-en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___ue-en"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___ue-en"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___ue-en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___ue-en"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___ue-en"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___ue-en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___ue-en"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___ue-en"
    }]
  }, {
    path: "/ue-en/newsletter",
    component: _a85becca,
    name: "newsletter___ue-en"
  }, {
    path: "/ue-en/Newsletter",
    component: _a85becca,
    name: "Newsletter___ue-en"
  }, {
    path: "/ue-en/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___ue-en"
  }, {
    path: "/ue-en/Page",
    component: _08b2ebf1,
    name: "Page___ue-en"
  }, {
    path: "/ue-en/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___ue-en"
  }, {
    path: "/ue-en/Product",
    component: _4bba9d7d,
    name: "Product___ue-en"
  }, {
    path: "/ue-en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___ue-en"
  }, {
    path: "/ue-en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___ue-en"
  }, {
    path: "/ue-en/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___ue-en"
  }, {
    path: "/ue-en/Route",
    component: _34cba952,
    name: "Route___ue-en"
  }, {
    path: "/ue-en/Search",
    component: _6f8b500a,
    name: "Search___ue-en"
  }, {
    path: "/ue-en/search-result",
    component: _6f8b500a,
    name: "search-result___ue-en"
  }, {
    path: "/ue-en/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___ue-en"
  }, {
    path: "/ue-en/work-with-us",
    component: _5dc06952,
    name: "work-with-us___ue-en"
  }, {
    path: "/ue-en/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___ue-en"
  }, {
    path: "/ue-it/boutiques",
    component: _34cef09d,
    name: "boutiques___ue-it",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___ue-it"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___ue-it"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___ue-it"
    }]
  }, {
    path: "/ue-it/Boutiques",
    component: _34cef09d,
    name: "Boutiques___ue-it"
  }, {
    path: "/ue-it/Cart",
    component: _544757fc,
    name: "Cart___ue-it"
  }, {
    path: "/ue-it/Category",
    component: _0b2841e0,
    name: "Category___ue-it"
  }, {
    path: "/ue-it/checkout",
    component: _48ef6e28,
    name: "checkout___ue-it",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___ue-it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___ue-it"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___ue-it"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___ue-it"
    }]
  }, {
    path: "/ue-it/Checkout",
    component: _48ef6e28,
    name: "Checkout___ue-it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___ue-it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___ue-it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___ue-it"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___ue-it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___ue-it"
    }]
  }, {
    path: "/ue-it/Contact",
    component: _b1da17a4,
    name: "Contact___ue-it"
  }, {
    path: "/ue-it/contacts",
    component: _b1da17a4,
    name: "contacts___ue-it"
  }, {
    path: "/ue-it/contatti",
    component: _b1da17a4,
    name: "contatti___ue-it"
  }, {
    path: "/ue-it/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___ue-it"
  }, {
    path: "/ue-it/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___ue-it"
  }, {
    path: "/ue-it/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___ue-it"
  }, {
    path: "/ue-it/Journal",
    component: _b971c876,
    name: "Journal___ue-it"
  }, {
    path: "/ue-it/JournalView",
    component: _bf42fcec,
    name: "JournalView___ue-it"
  }, {
    path: "/ue-it/MyAccount",
    component: _10189cef,
    name: "MyAccount___ue-it",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___ue-it"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___ue-it"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___ue-it"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___ue-it"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___ue-it"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___ue-it"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___ue-it"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___ue-it"
    }]
  }, {
    path: "/ue-it/newsletter",
    component: _a85becca,
    name: "newsletter___ue-it"
  }, {
    path: "/ue-it/Newsletter",
    component: _a85becca,
    name: "Newsletter___ue-it"
  }, {
    path: "/ue-it/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___ue-it"
  }, {
    path: "/ue-it/Page",
    component: _08b2ebf1,
    name: "Page___ue-it"
  }, {
    path: "/ue-it/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___ue-it"
  }, {
    path: "/ue-it/Product",
    component: _4bba9d7d,
    name: "Product___ue-it"
  }, {
    path: "/ue-it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___ue-it"
  }, {
    path: "/ue-it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___ue-it"
  }, {
    path: "/ue-it/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___ue-it"
  }, {
    path: "/ue-it/Route",
    component: _34cba952,
    name: "Route___ue-it"
  }, {
    path: "/ue-it/Search",
    component: _6f8b500a,
    name: "Search___ue-it"
  }, {
    path: "/ue-it/search-result",
    component: _6f8b500a,
    name: "search-result___ue-it"
  }, {
    path: "/ue-it/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___ue-it"
  }, {
    path: "/ue-it/work-with-us",
    component: _5dc06952,
    name: "work-with-us___ue-it"
  }, {
    path: "/ue-it/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___ue-it"
  }, {
    path: "/uee-en/boutiques",
    component: _34cef09d,
    name: "boutiques___uee-en",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___uee-en"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___uee-en"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___uee-en"
    }]
  }, {
    path: "/uee-en/Boutiques",
    component: _34cef09d,
    name: "Boutiques___uee-en"
  }, {
    path: "/uee-en/Cart",
    component: _544757fc,
    name: "Cart___uee-en"
  }, {
    path: "/uee-en/Category",
    component: _0b2841e0,
    name: "Category___uee-en"
  }, {
    path: "/uee-en/checkout",
    component: _48ef6e28,
    name: "checkout___uee-en",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___uee-en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___uee-en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___uee-en"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___uee-en"
    }]
  }, {
    path: "/uee-en/Checkout",
    component: _48ef6e28,
    name: "Checkout___uee-en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___uee-en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___uee-en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___uee-en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___uee-en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___uee-en"
    }]
  }, {
    path: "/uee-en/Contact",
    component: _b1da17a4,
    name: "Contact___uee-en"
  }, {
    path: "/uee-en/contacts",
    component: _b1da17a4,
    name: "contacts___uee-en"
  }, {
    path: "/uee-en/contatti",
    component: _b1da17a4,
    name: "contatti___uee-en"
  }, {
    path: "/uee-en/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___uee-en"
  }, {
    path: "/uee-en/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___uee-en"
  }, {
    path: "/uee-en/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___uee-en"
  }, {
    path: "/uee-en/Journal",
    component: _b971c876,
    name: "Journal___uee-en"
  }, {
    path: "/uee-en/JournalView",
    component: _bf42fcec,
    name: "JournalView___uee-en"
  }, {
    path: "/uee-en/MyAccount",
    component: _10189cef,
    name: "MyAccount___uee-en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___uee-en"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___uee-en"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___uee-en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___uee-en"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___uee-en"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___uee-en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___uee-en"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___uee-en"
    }]
  }, {
    path: "/uee-en/newsletter",
    component: _a85becca,
    name: "newsletter___uee-en"
  }, {
    path: "/uee-en/Newsletter",
    component: _a85becca,
    name: "Newsletter___uee-en"
  }, {
    path: "/uee-en/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___uee-en"
  }, {
    path: "/uee-en/Page",
    component: _08b2ebf1,
    name: "Page___uee-en"
  }, {
    path: "/uee-en/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___uee-en"
  }, {
    path: "/uee-en/Product",
    component: _4bba9d7d,
    name: "Product___uee-en"
  }, {
    path: "/uee-en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___uee-en"
  }, {
    path: "/uee-en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___uee-en"
  }, {
    path: "/uee-en/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___uee-en"
  }, {
    path: "/uee-en/Route",
    component: _34cba952,
    name: "Route___uee-en"
  }, {
    path: "/uee-en/Search",
    component: _6f8b500a,
    name: "Search___uee-en"
  }, {
    path: "/uee-en/search-result",
    component: _6f8b500a,
    name: "search-result___uee-en"
  }, {
    path: "/uee-en/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___uee-en"
  }, {
    path: "/uee-en/work-with-us",
    component: _5dc06952,
    name: "work-with-us___uee-en"
  }, {
    path: "/uee-en/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___uee-en"
  }, {
    path: "/uee-it/boutiques",
    component: _34cef09d,
    name: "boutiques___uee-it",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___uee-it"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___uee-it"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___uee-it"
    }]
  }, {
    path: "/uee-it/Boutiques",
    component: _34cef09d,
    name: "Boutiques___uee-it"
  }, {
    path: "/uee-it/Cart",
    component: _544757fc,
    name: "Cart___uee-it"
  }, {
    path: "/uee-it/Category",
    component: _0b2841e0,
    name: "Category___uee-it"
  }, {
    path: "/uee-it/checkout",
    component: _48ef6e28,
    name: "checkout___uee-it",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___uee-it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___uee-it"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___uee-it"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___uee-it"
    }]
  }, {
    path: "/uee-it/Checkout",
    component: _48ef6e28,
    name: "Checkout___uee-it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___uee-it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___uee-it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___uee-it"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___uee-it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___uee-it"
    }]
  }, {
    path: "/uee-it/Contact",
    component: _b1da17a4,
    name: "Contact___uee-it"
  }, {
    path: "/uee-it/contacts",
    component: _b1da17a4,
    name: "contacts___uee-it"
  }, {
    path: "/uee-it/contatti",
    component: _b1da17a4,
    name: "contatti___uee-it"
  }, {
    path: "/uee-it/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___uee-it"
  }, {
    path: "/uee-it/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___uee-it"
  }, {
    path: "/uee-it/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___uee-it"
  }, {
    path: "/uee-it/Journal",
    component: _b971c876,
    name: "Journal___uee-it"
  }, {
    path: "/uee-it/JournalView",
    component: _bf42fcec,
    name: "JournalView___uee-it"
  }, {
    path: "/uee-it/MyAccount",
    component: _10189cef,
    name: "MyAccount___uee-it",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___uee-it"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___uee-it"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___uee-it"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___uee-it"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___uee-it"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___uee-it"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___uee-it"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___uee-it"
    }]
  }, {
    path: "/uee-it/newsletter",
    component: _a85becca,
    name: "newsletter___uee-it"
  }, {
    path: "/uee-it/Newsletter",
    component: _a85becca,
    name: "Newsletter___uee-it"
  }, {
    path: "/uee-it/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___uee-it"
  }, {
    path: "/uee-it/Page",
    component: _08b2ebf1,
    name: "Page___uee-it"
  }, {
    path: "/uee-it/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___uee-it"
  }, {
    path: "/uee-it/Product",
    component: _4bba9d7d,
    name: "Product___uee-it"
  }, {
    path: "/uee-it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___uee-it"
  }, {
    path: "/uee-it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___uee-it"
  }, {
    path: "/uee-it/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___uee-it"
  }, {
    path: "/uee-it/Route",
    component: _34cba952,
    name: "Route___uee-it"
  }, {
    path: "/uee-it/Search",
    component: _6f8b500a,
    name: "Search___uee-it"
  }, {
    path: "/uee-it/search-result",
    component: _6f8b500a,
    name: "search-result___uee-it"
  }, {
    path: "/uee-it/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___uee-it"
  }, {
    path: "/uee-it/work-with-us",
    component: _5dc06952,
    name: "work-with-us___uee-it"
  }, {
    path: "/uee-it/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___uee-it"
  }, {
    path: "/us-en/boutiques",
    component: _34cef09d,
    name: "boutiques___us-en",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___us-en"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___us-en"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___us-en"
    }]
  }, {
    path: "/us-en/Boutiques",
    component: _34cef09d,
    name: "Boutiques___us-en"
  }, {
    path: "/us-en/Cart",
    component: _544757fc,
    name: "Cart___us-en"
  }, {
    path: "/us-en/Category",
    component: _0b2841e0,
    name: "Category___us-en"
  }, {
    path: "/us-en/checkout",
    component: _48ef6e28,
    name: "checkout___us-en",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___us-en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___us-en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___us-en"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___us-en"
    }]
  }, {
    path: "/us-en/Checkout",
    component: _48ef6e28,
    name: "Checkout___us-en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___us-en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___us-en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___us-en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___us-en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___us-en"
    }]
  }, {
    path: "/us-en/Contact",
    component: _b1da17a4,
    name: "Contact___us-en"
  }, {
    path: "/us-en/contacts",
    component: _b1da17a4,
    name: "contacts___us-en"
  }, {
    path: "/us-en/contatti",
    component: _b1da17a4,
    name: "contatti___us-en"
  }, {
    path: "/us-en/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___us-en"
  }, {
    path: "/us-en/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___us-en"
  }, {
    path: "/us-en/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___us-en"
  }, {
    path: "/us-en/Journal",
    component: _b971c876,
    name: "Journal___us-en"
  }, {
    path: "/us-en/JournalView",
    component: _bf42fcec,
    name: "JournalView___us-en"
  }, {
    path: "/us-en/MyAccount",
    component: _10189cef,
    name: "MyAccount___us-en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___us-en"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___us-en"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___us-en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___us-en"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___us-en"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___us-en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___us-en"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___us-en"
    }]
  }, {
    path: "/us-en/newsletter",
    component: _a85becca,
    name: "newsletter___us-en"
  }, {
    path: "/us-en/Newsletter",
    component: _a85becca,
    name: "Newsletter___us-en"
  }, {
    path: "/us-en/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___us-en"
  }, {
    path: "/us-en/Page",
    component: _08b2ebf1,
    name: "Page___us-en"
  }, {
    path: "/us-en/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___us-en"
  }, {
    path: "/us-en/Product",
    component: _4bba9d7d,
    name: "Product___us-en"
  }, {
    path: "/us-en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___us-en"
  }, {
    path: "/us-en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___us-en"
  }, {
    path: "/us-en/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___us-en"
  }, {
    path: "/us-en/Route",
    component: _34cba952,
    name: "Route___us-en"
  }, {
    path: "/us-en/Search",
    component: _6f8b500a,
    name: "Search___us-en"
  }, {
    path: "/us-en/search-result",
    component: _6f8b500a,
    name: "search-result___us-en"
  }, {
    path: "/us-en/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___us-en"
  }, {
    path: "/us-en/work-with-us",
    component: _5dc06952,
    name: "work-with-us___us-en"
  }, {
    path: "/us-en/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___us-en"
  }, {
    path: "/us-it/boutiques",
    component: _34cef09d,
    name: "boutiques___us-it",
    children: [{
      path: "divo-kids",
      component: _34cef09d,
      name: "divo-kids___us-it"
    }, {
      path: "divo-pontedera",
      component: _34cef09d,
      name: "divo-pontedera___us-it"
    }, {
      path: "divodi",
      component: _34cef09d,
      name: "divodi___us-it"
    }]
  }, {
    path: "/us-it/Boutiques",
    component: _34cef09d,
    name: "Boutiques___us-it"
  }, {
    path: "/us-it/Cart",
    component: _544757fc,
    name: "Cart___us-it"
  }, {
    path: "/us-it/Category",
    component: _0b2841e0,
    name: "Category___us-it"
  }, {
    path: "/us-it/checkout",
    component: _48ef6e28,
    name: "checkout___us-it",
    children: [{
      path: "payment",
      component: _74dcbd02,
      name: "payment___us-it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___us-it"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___us-it"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___us-it"
    }]
  }, {
    path: "/us-it/Checkout",
    component: _48ef6e28,
    name: "Checkout___us-it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___us-it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___us-it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___us-it"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___us-it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___us-it"
    }]
  }, {
    path: "/us-it/Contact",
    component: _b1da17a4,
    name: "Contact___us-it"
  }, {
    path: "/us-it/contacts",
    component: _b1da17a4,
    name: "contacts___us-it"
  }, {
    path: "/us-it/contatti",
    component: _b1da17a4,
    name: "contatti___us-it"
  }, {
    path: "/us-it/guest-returns",
    component: _5bdc71ad,
    name: "guest-returns___us-it"
  }, {
    path: "/us-it/GuestReturns",
    component: _5bdc71ad,
    name: "GuestReturns___us-it"
  }, {
    path: "/us-it/HomePage",
    component: _5a6ec0f0,
    name: "HomePage___us-it"
  }, {
    path: "/us-it/Journal",
    component: _b971c876,
    name: "Journal___us-it"
  }, {
    path: "/us-it/JournalView",
    component: _bf42fcec,
    name: "JournalView___us-it"
  }, {
    path: "/us-it/MyAccount",
    component: _10189cef,
    name: "MyAccount___us-it",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___us-it"
    }, {
      path: "MyChanges",
      component: _41bcf037,
      name: "MyAccount-MyChanges___us-it"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___us-it"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___us-it"
    }, {
      path: "MyReturns",
      component: _4bc3b6b7,
      name: "MyAccount-MyReturns___us-it"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___us-it"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___us-it"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___us-it"
    }]
  }, {
    path: "/us-it/newsletter",
    component: _a85becca,
    name: "newsletter___us-it"
  }, {
    path: "/us-it/Newsletter",
    component: _a85becca,
    name: "Newsletter___us-it"
  }, {
    path: "/us-it/OurBrands",
    component: _4eee96c6,
    name: "OurBrands___us-it"
  }, {
    path: "/us-it/Page",
    component: _08b2ebf1,
    name: "Page___us-it"
  }, {
    path: "/us-it/PayloadPage",
    component: _680ee06a,
    name: "PayloadPage___us-it"
  }, {
    path: "/us-it/Product",
    component: _4bba9d7d,
    name: "Product___us-it"
  }, {
    path: "/us-it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___us-it"
  }, {
    path: "/us-it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___us-it"
  }, {
    path: "/us-it/reso-guest",
    component: _5bdc71ad,
    name: "reso-guest___us-it"
  }, {
    path: "/us-it/Route",
    component: _34cba952,
    name: "Route___us-it"
  }, {
    path: "/us-it/Search",
    component: _6f8b500a,
    name: "Search___us-it"
  }, {
    path: "/us-it/search-result",
    component: _6f8b500a,
    name: "search-result___us-it"
  }, {
    path: "/us-it/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___us-it"
  }, {
    path: "/us-it/work-with-us",
    component: _5dc06952,
    name: "work-with-us___us-it"
  }, {
    path: "/us-it/WorkWithUs",
    component: _5dc06952,
    name: "WorkWithUs___us-it"
  }, {
    path: "/ae-en/c/:categoryId",
    component: _0b2841e0,
    name: "category___ae-en"
  }, {
    path: "/ae-en/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___ae-en"
  }, {
    path: "/ae-en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___ae-en"
  }, {
    path: "/ae-en/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___ae-en"
  }, {
    path: "/ae-en/p/:productId",
    component: _4bba9d7d,
    name: "product___ae-en"
  }, {
    path: "/ae-it/c/:categoryId",
    component: _0b2841e0,
    name: "category___ae-it"
  }, {
    path: "/ae-it/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___ae-it"
  }, {
    path: "/ae-it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___ae-it"
  }, {
    path: "/ae-it/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___ae-it"
  }, {
    path: "/ae-it/p/:productId",
    component: _4bba9d7d,
    name: "product___ae-it"
  }, {
    path: "/au-en/c/:categoryId",
    component: _0b2841e0,
    name: "category___au-en"
  }, {
    path: "/au-en/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___au-en"
  }, {
    path: "/au-en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___au-en"
  }, {
    path: "/au-en/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___au-en"
  }, {
    path: "/au-en/p/:productId",
    component: _4bba9d7d,
    name: "product___au-en"
  }, {
    path: "/au-it/c/:categoryId",
    component: _0b2841e0,
    name: "category___au-it"
  }, {
    path: "/au-it/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___au-it"
  }, {
    path: "/au-it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___au-it"
  }, {
    path: "/au-it/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___au-it"
  }, {
    path: "/au-it/p/:productId",
    component: _4bba9d7d,
    name: "product___au-it"
  }, {
    path: "/bh-en/c/:categoryId",
    component: _0b2841e0,
    name: "category___bh-en"
  }, {
    path: "/bh-en/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___bh-en"
  }, {
    path: "/bh-en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___bh-en"
  }, {
    path: "/bh-en/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___bh-en"
  }, {
    path: "/bh-en/p/:productId",
    component: _4bba9d7d,
    name: "product___bh-en"
  }, {
    path: "/bh-it/c/:categoryId",
    component: _0b2841e0,
    name: "category___bh-it"
  }, {
    path: "/bh-it/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___bh-it"
  }, {
    path: "/bh-it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___bh-it"
  }, {
    path: "/bh-it/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___bh-it"
  }, {
    path: "/bh-it/p/:productId",
    component: _4bba9d7d,
    name: "product___bh-it"
  }, {
    path: "/ca-en/c/:categoryId",
    component: _0b2841e0,
    name: "category___ca-en"
  }, {
    path: "/ca-en/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___ca-en"
  }, {
    path: "/ca-en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___ca-en"
  }, {
    path: "/ca-en/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___ca-en"
  }, {
    path: "/ca-en/p/:productId",
    component: _4bba9d7d,
    name: "product___ca-en"
  }, {
    path: "/ca-it/c/:categoryId",
    component: _0b2841e0,
    name: "category___ca-it"
  }, {
    path: "/ca-it/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___ca-it"
  }, {
    path: "/ca-it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___ca-it"
  }, {
    path: "/ca-it/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___ca-it"
  }, {
    path: "/ca-it/p/:productId",
    component: _4bba9d7d,
    name: "product___ca-it"
  }, {
    path: "/ch-en/c/:categoryId",
    component: _0b2841e0,
    name: "category___ch-en"
  }, {
    path: "/ch-en/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___ch-en"
  }, {
    path: "/ch-en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___ch-en"
  }, {
    path: "/ch-en/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___ch-en"
  }, {
    path: "/ch-en/p/:productId",
    component: _4bba9d7d,
    name: "product___ch-en"
  }, {
    path: "/ch-it/c/:categoryId",
    component: _0b2841e0,
    name: "category___ch-it"
  }, {
    path: "/ch-it/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___ch-it"
  }, {
    path: "/ch-it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___ch-it"
  }, {
    path: "/ch-it/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___ch-it"
  }, {
    path: "/ch-it/p/:productId",
    component: _4bba9d7d,
    name: "product___ch-it"
  }, {
    path: "/cn-en/c/:categoryId",
    component: _0b2841e0,
    name: "category___cn-en"
  }, {
    path: "/cn-en/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___cn-en"
  }, {
    path: "/cn-en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___cn-en"
  }, {
    path: "/cn-en/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___cn-en"
  }, {
    path: "/cn-en/p/:productId",
    component: _4bba9d7d,
    name: "product___cn-en"
  }, {
    path: "/cn-it/c/:categoryId",
    component: _0b2841e0,
    name: "category___cn-it"
  }, {
    path: "/cn-it/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___cn-it"
  }, {
    path: "/cn-it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___cn-it"
  }, {
    path: "/cn-it/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___cn-it"
  }, {
    path: "/cn-it/p/:productId",
    component: _4bba9d7d,
    name: "product___cn-it"
  }, {
    path: "/de-en/c/:categoryId",
    component: _0b2841e0,
    name: "category___de-en"
  }, {
    path: "/de-en/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___de-en"
  }, {
    path: "/de-en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___de-en"
  }, {
    path: "/de-en/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___de-en"
  }, {
    path: "/de-en/p/:productId",
    component: _4bba9d7d,
    name: "product___de-en"
  }, {
    path: "/de-it/c/:categoryId",
    component: _0b2841e0,
    name: "category___de-it"
  }, {
    path: "/de-it/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___de-it"
  }, {
    path: "/de-it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___de-it"
  }, {
    path: "/de-it/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___de-it"
  }, {
    path: "/de-it/p/:productId",
    component: _4bba9d7d,
    name: "product___de-it"
  }, {
    path: "/eue-en/c/:categoryId",
    component: _0b2841e0,
    name: "category___eue-en"
  }, {
    path: "/eue-en/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___eue-en"
  }, {
    path: "/eue-en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___eue-en"
  }, {
    path: "/eue-en/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___eue-en"
  }, {
    path: "/eue-en/p/:productId",
    component: _4bba9d7d,
    name: "product___eue-en"
  }, {
    path: "/eue-it/c/:categoryId",
    component: _0b2841e0,
    name: "category___eue-it"
  }, {
    path: "/eue-it/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___eue-it"
  }, {
    path: "/eue-it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___eue-it"
  }, {
    path: "/eue-it/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___eue-it"
  }, {
    path: "/eue-it/p/:productId",
    component: _4bba9d7d,
    name: "product___eue-it"
  }, {
    path: "/gb-en/c/:categoryId",
    component: _0b2841e0,
    name: "category___gb-en"
  }, {
    path: "/gb-en/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___gb-en"
  }, {
    path: "/gb-en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___gb-en"
  }, {
    path: "/gb-en/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___gb-en"
  }, {
    path: "/gb-en/p/:productId",
    component: _4bba9d7d,
    name: "product___gb-en"
  }, {
    path: "/gb-it/c/:categoryId",
    component: _0b2841e0,
    name: "category___gb-it"
  }, {
    path: "/gb-it/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___gb-it"
  }, {
    path: "/gb-it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___gb-it"
  }, {
    path: "/gb-it/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___gb-it"
  }, {
    path: "/gb-it/p/:productId",
    component: _4bba9d7d,
    name: "product___gb-it"
  }, {
    path: "/hk-en/c/:categoryId",
    component: _0b2841e0,
    name: "category___hk-en"
  }, {
    path: "/hk-en/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___hk-en"
  }, {
    path: "/hk-en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___hk-en"
  }, {
    path: "/hk-en/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___hk-en"
  }, {
    path: "/hk-en/p/:productId",
    component: _4bba9d7d,
    name: "product___hk-en"
  }, {
    path: "/hk-it/c/:categoryId",
    component: _0b2841e0,
    name: "category___hk-it"
  }, {
    path: "/hk-it/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___hk-it"
  }, {
    path: "/hk-it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___hk-it"
  }, {
    path: "/hk-it/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___hk-it"
  }, {
    path: "/hk-it/p/:productId",
    component: _4bba9d7d,
    name: "product___hk-it"
  }, {
    path: "/it-en/c/:categoryId",
    component: _0b2841e0,
    name: "category___it-en"
  }, {
    path: "/it-en/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___it-en"
  }, {
    path: "/it-en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___it-en"
  }, {
    path: "/it-en/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___it-en"
  }, {
    path: "/it-en/p/:productId",
    component: _4bba9d7d,
    name: "product___it-en"
  }, {
    path: "/it-it/c/:categoryId",
    component: _0b2841e0,
    name: "category___it-it"
  }, {
    path: "/it-it/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___it-it"
  }, {
    path: "/it-it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___it-it"
  }, {
    path: "/it-it/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___it-it"
  }, {
    path: "/it-it/p/:productId",
    component: _4bba9d7d,
    name: "product___it-it"
  }, {
    path: "/jp-en/c/:categoryId",
    component: _0b2841e0,
    name: "category___jp-en"
  }, {
    path: "/jp-en/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___jp-en"
  }, {
    path: "/jp-en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___jp-en"
  }, {
    path: "/jp-en/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___jp-en"
  }, {
    path: "/jp-en/p/:productId",
    component: _4bba9d7d,
    name: "product___jp-en"
  }, {
    path: "/jp-it/c/:categoryId",
    component: _0b2841e0,
    name: "category___jp-it"
  }, {
    path: "/jp-it/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___jp-it"
  }, {
    path: "/jp-it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___jp-it"
  }, {
    path: "/jp-it/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___jp-it"
  }, {
    path: "/jp-it/p/:productId",
    component: _4bba9d7d,
    name: "product___jp-it"
  }, {
    path: "/kr-en/c/:categoryId",
    component: _0b2841e0,
    name: "category___kr-en"
  }, {
    path: "/kr-en/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___kr-en"
  }, {
    path: "/kr-en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___kr-en"
  }, {
    path: "/kr-en/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___kr-en"
  }, {
    path: "/kr-en/p/:productId",
    component: _4bba9d7d,
    name: "product___kr-en"
  }, {
    path: "/kr-it/c/:categoryId",
    component: _0b2841e0,
    name: "category___kr-it"
  }, {
    path: "/kr-it/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___kr-it"
  }, {
    path: "/kr-it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___kr-it"
  }, {
    path: "/kr-it/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___kr-it"
  }, {
    path: "/kr-it/p/:productId",
    component: _4bba9d7d,
    name: "product___kr-it"
  }, {
    path: "/kw-en/c/:categoryId",
    component: _0b2841e0,
    name: "category___kw-en"
  }, {
    path: "/kw-en/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___kw-en"
  }, {
    path: "/kw-en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___kw-en"
  }, {
    path: "/kw-en/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___kw-en"
  }, {
    path: "/kw-en/p/:productId",
    component: _4bba9d7d,
    name: "product___kw-en"
  }, {
    path: "/kw-it/c/:categoryId",
    component: _0b2841e0,
    name: "category___kw-it"
  }, {
    path: "/kw-it/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___kw-it"
  }, {
    path: "/kw-it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___kw-it"
  }, {
    path: "/kw-it/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___kw-it"
  }, {
    path: "/kw-it/p/:productId",
    component: _4bba9d7d,
    name: "product___kw-it"
  }, {
    path: "/mo-en/c/:categoryId",
    component: _0b2841e0,
    name: "category___mo-en"
  }, {
    path: "/mo-en/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___mo-en"
  }, {
    path: "/mo-en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___mo-en"
  }, {
    path: "/mo-en/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___mo-en"
  }, {
    path: "/mo-en/p/:productId",
    component: _4bba9d7d,
    name: "product___mo-en"
  }, {
    path: "/mo-it/c/:categoryId",
    component: _0b2841e0,
    name: "category___mo-it"
  }, {
    path: "/mo-it/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___mo-it"
  }, {
    path: "/mo-it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___mo-it"
  }, {
    path: "/mo-it/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___mo-it"
  }, {
    path: "/mo-it/p/:productId",
    component: _4bba9d7d,
    name: "product___mo-it"
  }, {
    path: "/qa-en/c/:categoryId",
    component: _0b2841e0,
    name: "category___qa-en"
  }, {
    path: "/qa-en/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___qa-en"
  }, {
    path: "/qa-en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___qa-en"
  }, {
    path: "/qa-en/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___qa-en"
  }, {
    path: "/qa-en/p/:productId",
    component: _4bba9d7d,
    name: "product___qa-en"
  }, {
    path: "/qa-it/c/:categoryId",
    component: _0b2841e0,
    name: "category___qa-it"
  }, {
    path: "/qa-it/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___qa-it"
  }, {
    path: "/qa-it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___qa-it"
  }, {
    path: "/qa-it/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___qa-it"
  }, {
    path: "/qa-it/p/:productId",
    component: _4bba9d7d,
    name: "product___qa-it"
  }, {
    path: "/row-en/c/:categoryId",
    component: _0b2841e0,
    name: "category___row-en"
  }, {
    path: "/row-en/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___row-en"
  }, {
    path: "/row-en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___row-en"
  }, {
    path: "/row-en/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___row-en"
  }, {
    path: "/row-en/p/:productId",
    component: _4bba9d7d,
    name: "product___row-en"
  }, {
    path: "/row-it/c/:categoryId",
    component: _0b2841e0,
    name: "category___row-it"
  }, {
    path: "/row-it/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___row-it"
  }, {
    path: "/row-it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___row-it"
  }, {
    path: "/row-it/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___row-it"
  }, {
    path: "/row-it/p/:productId",
    component: _4bba9d7d,
    name: "product___row-it"
  }, {
    path: "/sg-en/c/:categoryId",
    component: _0b2841e0,
    name: "category___sg-en"
  }, {
    path: "/sg-en/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___sg-en"
  }, {
    path: "/sg-en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___sg-en"
  }, {
    path: "/sg-en/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___sg-en"
  }, {
    path: "/sg-en/p/:productId",
    component: _4bba9d7d,
    name: "product___sg-en"
  }, {
    path: "/sg-it/c/:categoryId",
    component: _0b2841e0,
    name: "category___sg-it"
  }, {
    path: "/sg-it/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___sg-it"
  }, {
    path: "/sg-it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___sg-it"
  }, {
    path: "/sg-it/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___sg-it"
  }, {
    path: "/sg-it/p/:productId",
    component: _4bba9d7d,
    name: "product___sg-it"
  }, {
    path: "/ue-en/c/:categoryId",
    component: _0b2841e0,
    name: "category___ue-en"
  }, {
    path: "/ue-en/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___ue-en"
  }, {
    path: "/ue-en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___ue-en"
  }, {
    path: "/ue-en/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___ue-en"
  }, {
    path: "/ue-en/p/:productId",
    component: _4bba9d7d,
    name: "product___ue-en"
  }, {
    path: "/ue-it/c/:categoryId",
    component: _0b2841e0,
    name: "category___ue-it"
  }, {
    path: "/ue-it/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___ue-it"
  }, {
    path: "/ue-it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___ue-it"
  }, {
    path: "/ue-it/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___ue-it"
  }, {
    path: "/ue-it/p/:productId",
    component: _4bba9d7d,
    name: "product___ue-it"
  }, {
    path: "/uee-en/c/:categoryId",
    component: _0b2841e0,
    name: "category___uee-en"
  }, {
    path: "/uee-en/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___uee-en"
  }, {
    path: "/uee-en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___uee-en"
  }, {
    path: "/uee-en/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___uee-en"
  }, {
    path: "/uee-en/p/:productId",
    component: _4bba9d7d,
    name: "product___uee-en"
  }, {
    path: "/uee-it/c/:categoryId",
    component: _0b2841e0,
    name: "category___uee-it"
  }, {
    path: "/uee-it/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___uee-it"
  }, {
    path: "/uee-it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___uee-it"
  }, {
    path: "/uee-it/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___uee-it"
  }, {
    path: "/uee-it/p/:productId",
    component: _4bba9d7d,
    name: "product___uee-it"
  }, {
    path: "/us-en/c/:categoryId",
    component: _0b2841e0,
    name: "category___us-en"
  }, {
    path: "/us-en/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___us-en"
  }, {
    path: "/us-en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___us-en"
  }, {
    path: "/us-en/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___us-en"
  }, {
    path: "/us-en/p/:productId",
    component: _4bba9d7d,
    name: "product___us-en"
  }, {
    path: "/us-it/c/:categoryId",
    component: _0b2841e0,
    name: "category___us-it"
  }, {
    path: "/us-it/magazine-view/:slug",
    component: _bf42fcec,
    name: "magazine-view___us-it"
  }, {
    path: "/us-it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___us-it"
  }, {
    path: "/us-it/ourbrands/:gender?",
    component: _4eee96c6,
    name: "ourbrands___us-it"
  }, {
    path: "/us-it/p/:productId",
    component: _4bba9d7d,
    name: "product___us-it"
  }, {
    path: "/ae-en/:path+",
    component: _34cba952,
    name: "route-resolver___ae-en"
  }, {
    path: "/ae-it/:path+",
    component: _34cba952,
    name: "route-resolver___ae-it"
  }, {
    path: "/au-en/:path+",
    component: _34cba952,
    name: "route-resolver___au-en"
  }, {
    path: "/au-it/:path+",
    component: _34cba952,
    name: "route-resolver___au-it"
  }, {
    path: "/bh-en/:path+",
    component: _34cba952,
    name: "route-resolver___bh-en"
  }, {
    path: "/bh-it/:path+",
    component: _34cba952,
    name: "route-resolver___bh-it"
  }, {
    path: "/ca-en/:path+",
    component: _34cba952,
    name: "route-resolver___ca-en"
  }, {
    path: "/ca-it/:path+",
    component: _34cba952,
    name: "route-resolver___ca-it"
  }, {
    path: "/ch-en/:path+",
    component: _34cba952,
    name: "route-resolver___ch-en"
  }, {
    path: "/ch-it/:path+",
    component: _34cba952,
    name: "route-resolver___ch-it"
  }, {
    path: "/cn-en/:path+",
    component: _34cba952,
    name: "route-resolver___cn-en"
  }, {
    path: "/cn-it/:path+",
    component: _34cba952,
    name: "route-resolver___cn-it"
  }, {
    path: "/de-en/:path+",
    component: _34cba952,
    name: "route-resolver___de-en"
  }, {
    path: "/de-it/:path+",
    component: _34cba952,
    name: "route-resolver___de-it"
  }, {
    path: "/eue-en/:path+",
    component: _34cba952,
    name: "route-resolver___eue-en"
  }, {
    path: "/eue-it/:path+",
    component: _34cba952,
    name: "route-resolver___eue-it"
  }, {
    path: "/gb-en/:path+",
    component: _34cba952,
    name: "route-resolver___gb-en"
  }, {
    path: "/gb-it/:path+",
    component: _34cba952,
    name: "route-resolver___gb-it"
  }, {
    path: "/hk-en/:path+",
    component: _34cba952,
    name: "route-resolver___hk-en"
  }, {
    path: "/hk-it/:path+",
    component: _34cba952,
    name: "route-resolver___hk-it"
  }, {
    path: "/it-en/:path+",
    component: _34cba952,
    name: "route-resolver___it-en"
  }, {
    path: "/it-it/:path+",
    component: _34cba952,
    name: "route-resolver___it-it"
  }, {
    path: "/jp-en/:path+",
    component: _34cba952,
    name: "route-resolver___jp-en"
  }, {
    path: "/jp-it/:path+",
    component: _34cba952,
    name: "route-resolver___jp-it"
  }, {
    path: "/kr-en/:path+",
    component: _34cba952,
    name: "route-resolver___kr-en"
  }, {
    path: "/kr-it/:path+",
    component: _34cba952,
    name: "route-resolver___kr-it"
  }, {
    path: "/kw-en/:path+",
    component: _34cba952,
    name: "route-resolver___kw-en"
  }, {
    path: "/kw-it/:path+",
    component: _34cba952,
    name: "route-resolver___kw-it"
  }, {
    path: "/mo-en/:path+",
    component: _34cba952,
    name: "route-resolver___mo-en"
  }, {
    path: "/mo-it/:path+",
    component: _34cba952,
    name: "route-resolver___mo-it"
  }, {
    path: "/qa-en/:path+",
    component: _34cba952,
    name: "route-resolver___qa-en"
  }, {
    path: "/qa-it/:path+",
    component: _34cba952,
    name: "route-resolver___qa-it"
  }, {
    path: "/row-en/:path+",
    component: _34cba952,
    name: "route-resolver___row-en"
  }, {
    path: "/row-it/:path+",
    component: _34cba952,
    name: "route-resolver___row-it"
  }, {
    path: "/sg-en/:path+",
    component: _34cba952,
    name: "route-resolver___sg-en"
  }, {
    path: "/sg-it/:path+",
    component: _34cba952,
    name: "route-resolver___sg-it"
  }, {
    path: "/ue-en/:path+",
    component: _34cba952,
    name: "route-resolver___ue-en"
  }, {
    path: "/ue-it/:path+",
    component: _34cba952,
    name: "route-resolver___ue-it"
  }, {
    path: "/uee-en/:path+",
    component: _34cba952,
    name: "route-resolver___uee-en"
  }, {
    path: "/uee-it/:path+",
    component: _34cba952,
    name: "route-resolver___uee-it"
  }, {
    path: "/us-en/:path+",
    component: _34cba952,
    name: "route-resolver___us-en"
  }, {
    path: "/us-it/:path+",
    component: _34cba952,
    name: "route-resolver___us-it"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
