import { sharedRef } from '@vue-storefront/core';
import { useContent as useContentComposable } from '@gemini-vsf/composables';
import { CacheTagPrefix, useCache } from '@gemini/vsf-cache';
import { CmsBlock } from '@gemini-vsf/api-client';
import { useContext } from '@nuxtjs/composition-api';
import axios from 'axios';
import { CacheTag, ContentEditorBlock } from '~/types/common';

const useContent = (key = 'custom-content') => {
  const blocks = sharedRef<CmsBlock[]>([], key);
  const page = sharedRef<object>({}, key);

  const { addTags } = useCache();
  const {
    blocks: blocksComposable,
    loadBlocks: loadBlocksComposable,
    page: pageComposable,
    loadContent: loadContentComposable,
  } = useContentComposable(key);
  const {
    app: {
      $vsf: {
        $gemini: { config },
      },
    },
  } = useContext();

  const fetchDataUsingCache = async (url: string, data: { identifier: string }) =>
    await axios({
      method: 'post',
      url,
      data,
    }).then((res) => res.data);

  const getBlock = async (identifier: string) => {
    if (process.client && config['redisEnabled'] && identifier) {
      return await fetchDataUsingCache('/api/get-block', { identifier });
    }
    await loadBlocksComposable({ identifiers: [identifier] });
    return blocksComposable.value?.[0];
  };

  const getContent = async (identifier: string) => {
    if (process.client && config['redisEnabled'] && identifier) {
      return await fetchDataUsingCache('/api/get-page', { identifier });
    }
    await loadContentComposable({ identifier });
    return pageComposable.value;
  };

  const loadBlocks = async (params: { identifiers: string[] }): Promise<void> => {
    try {
      blocks.value = [];
      const identifiers = params.identifiers.filter(Boolean);
      blocks.value = await Promise.all(identifiers.map(async (i) => await getBlock(i)));
      // filter out identifiers that do not have a corresponding block with content
      const cacheTags = blocks.value.reduce((acc: CacheTag[], block: ContentEditorBlock | null) => {
        if (block?.content) {
          acc.push({ prefix: CacheTagPrefix.Block, value: block.identifier });
        }
        return acc;
      }, []);
      if (cacheTags.length > 0) {
        addTags(cacheTags);
      }
    } catch (error) {
      console.warn(`Unable to load blocks: ${params.identifiers?.join(', ') || 'unknown'}`);
      console.error(error);
    }
  };

  const loadContent = async (params: { identifier: string }): Promise<void> => {
    try {
      page.value = {};
      const identifier = params.identifier;
      page.value = await getContent(identifier);
      addTags([{ prefix: CacheTagPrefix.Block, value: identifier }]);
    } catch (error) {
      console.warn(`Unable to load page: ${params.identifier || 'unknown'}`);
      console.error(error);
    }
  };

  return {
    ...useContentComposable(key),
    loadContent,
    loadBlocks,
    blocks,
    page,
  };
};

export default useContent;
